import { useContext } from "react";
import SignUpContext from "../../../contexts/sign-up.ctx";
import { SelectorRoles } from "../../../types";
import BaseForm from "../../@Base/BaseForm/BaseForm";
import RoleButton from "../../@Shared/RoleButton/RoleButton";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import Logo from "../../@Shared/Logo/Logo";
import "./SignUpForm.css";

interface ContainerProps {}

const SignUpForm: React.FC<ContainerProps> = (props) => {
  const signUpCtx = useContext(SignUpContext);

  return (
    <section className="signup-form" data-testid="signup-form">
      {signUpCtx.signUpStep == "USER_ROLE_CHOICE" ? (
        <div className="">
          <header
            className="signup-form__header-container"
            data-testid="start-logo"
          >
            <Logo size="xs" />
          </header>
          <RoleButton
            role="RESTAURANT"
            selected={signUpCtx.userRole == "RESTAURANT"}
            onClick={() => {
              signUpCtx.setUserToSignUpRole("RESTAURANT");
              signUpCtx.setSignUpStepHandler("SIGNUP_FORM");
            }}
          />
          <RoleButton
            role="COURIER"
            selected={signUpCtx.userRole == "COURIER"}
            onClick={() => {
              signUpCtx.setUserToSignUpRole("COURIER");
              signUpCtx.setSignUpStepHandler("SIGNUP_FORM");
            }}
          />
        </div>
      ) : (
        <BaseForm
          schema={signUpCtx.formSchema}
          onSubmit={(data: {
            email: string;
            password: string;
            companyName: string;
            phone: string;
          }) =>
            signUpCtx.formSubmitHandler(
              data.email,
              data.password,
              data.companyName,
              data.phone
            )
          }
          submitText="Créer mon compte"
          isBusy={signUpCtx.isBusy}
          title="Mes informations :"
        />
      )}
    </section>
  );
};

export default SignUpForm;
// NOTE : Sign Up is now only available for courier
// TODO : might be a good idea to add courier informations to prevent emptyness bug
