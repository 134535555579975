import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { IonImg } from "@ionic/react";
import stripeLogo from "../../assets/logo/stripe_logo.png";
import "./Download.css";
import HomeContext from "../../contexts/home.ctx";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import androidLogo from "../../assets/images/androidLogo.png";
import iosLogo from "../../assets/images/iosLogo.png";
import BaseButton from "../@Base/BaseButton/BaseButton";

interface ContainerProps {}

const Download: React.FC = (props) => {
  const homeCtx = useContext(HomeContext);
  const subOrderCtx = useContext(SubscriptionOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const authCtx = useContext(AuthContext);

  return (
    <div className="download__container">
      <div className="download__header">Pour de meilleure performances :</div>
      <div className="download__text">
        Veuillez télécharger notre application compatible IOS et android.
      </div>
      <div className="download__row-container">
        <div className="download__row-item">
          <IonImg src={iosLogo} className="download-row-logo-ios" />
          <BaseButton expand type="submit">
            Télécharger pour iOS
          </BaseButton>
          <div className="download__text-small">Pour iPhone iPad etc..</div>
        </div>

        <div className="download__row-item-text">Ou</div>
        <div className="download__row-item">
          <IonImg src={androidLogo} className="download-row-logo-android" />
          <a
            href="https://drive.google.com/file/d/1iR9zQkleOr6bIUlVZ4BtQJghrUhe7RMK/view?usp=sharing"
            download={"iziup-app.apk"}
          >
            <BaseButton expand type="submit">
              Télécharger pour android
            </BaseButton>
          </a>
          <div className="download__text-small">Pour Smasung et autre</div>
        </div>
      </div>
      <div className="download__text-small">
        Utilisez vos identifiants iziUP.fr afin de vous connecter.
      </div>
      <div className="download__text">
        Sur Windows et autres ordinateurs, Veuillez utiliser notre version web.
      </div>
    </div>
  );
};

export default Download;
