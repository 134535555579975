import ResetPasswordForm from "../../../components/Start/ResetPasswordForm/ResetPasswordForm";
import { ResetPasswordContextProvider } from "../../../contexts/reset-password.ctx";
import StartLayout, {
  StartLayoutDispositions,
} from "../../../components/Layouts/StartLayout/StartLayout";

const ResetPasswordPage: React.FC = () => {
  return (
    <ResetPasswordContextProvider>
      <StartLayout disposition={StartLayoutDispositions.PASSWORD_RESET}>
        <ResetPasswordForm />
      </StartLayout>
    </ResetPasswordContextProvider>
  );
};

export default ResetPasswordPage;
