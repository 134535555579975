import "./BaseForm.css";
import BaseTextInput from "../BaseTextInput/BaseTextInput";
import { useForm, Controller } from "react-hook-form";
import { InputSchema } from "../../../types";
import buildFormRules from "../../../helpers/buildFormRules";
import BaseButton from "../BaseButton/BaseButton";
import errorMessages from "../../../messages/error";

interface ContainerProps {
  schema: InputSchema[];
  onSubmit: Function;
  submitText?: string;
  isBusy?: boolean;
  title?: string;
  tightButton?: boolean;
  submitDisabled?: boolean;
  prefill?: any;
}

const BaseForm: React.FC<ContainerProps> = (props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    getValues,
  } = useForm();

  const onSubmitHandler = (data: any) => {
    // REVIEW NOT_THE_SAME method to check is ok but not unit tested
    let hasError = false;
    props.schema
      .filter((input) => input.sameAs != null)
      .map((input) => {
        if (getValues(input.sameAs as string) !== getValues(input.name)) {
          hasError = true;
          setError(input.name, {
            type: "string",
            message: errorMessages.NOT_THE_SAME,
          });
        }
      });
    if (hasError) {
      return;
    } else {
      props.onSubmit(data);
    }
  };

  const getDefaultValueIfExists = (inputName: string) => {
    return props.prefill[inputName] || "";
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className="base-form"
      data-testid="base-form"
      noValidate
    >
      <div className="base-form__fields">
        {props.title && <h3>{props.title}</h3>}
        {props.schema.map((input, key) => (
          <Controller
            key={key}
            name={input.name}
            control={control}
            defaultValue={
              props.prefill ? getDefaultValueIfExists(input.name) : ""
            }
            rules={buildFormRules(input)}
            render={({ field }) => (
              <BaseTextInput
                type={input.type}
                label={input.label}
                controller={field}
                hintLink={input.hintLink}
                error={errors[input.name]?.message}
              />
            )}
          />
        ))}
      </div>
      <div className="base-form__submit-button">
        <BaseButton
          big
          expand
          type="submit"
          isLoading={props.isBusy}
          tight={props.tightButton}
          disabled={props.submitDisabled}
        >
          {props.submitText || "Valider"}
        </BaseButton>
      </div>
    </form>
  );
};

export default BaseForm;
