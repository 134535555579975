import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import "./ChooseSubscriptionPlan.css";
import HomeContext from "../../contexts/home.ctx";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import SubscriptionInfoLayout from "../SubscriptionInfoLayout/SubscriptionInfoLayout";
import BaseButton from "../@Base/BaseButton/BaseButton";
import routes from "../../routes";
interface ContainerProps {}

const ChooseSubscriptionPlan: React.FC = (props) => {
  const homeCtx = useContext(HomeContext);
  const subOrderCtx = useContext(SubscriptionOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  let history = useHistory();
  const authCtx = useContext(AuthContext);

  return (
    <div className="choose-subscription-plan__container">
      <div className="choose-subscription-plan__mobile-container">
        <div className="choose-subscription-plan__header">Notre offre</div>
        <SubscriptionInfoLayout />

        <div className="choose-subscription-plan__button-container">
          <BaseButton
            expand
            type="submit"
            onClick={() => {
              if (authCtx.authState === AuthStates.AUTH) {
                history.push(routes.ACCOUNT_PAGE);
              } else {
                history.push(routes.SIGN_UP_PAGE);
              }
            }}
          >
            S'inscrire
          </BaseButton>
        </div>
      </div>

      <div className="choose-subscription-plan__desktop-container">
        <div className="choose-subscription-plan__header">Notre offre</div>
        <SubscriptionInfoLayout />
      </div>
    </div>
  );
};

export default ChooseSubscriptionPlan;
