import "./SubscriptionInfoLayout.css";

import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { IonImg, IonIcon } from "@ionic/react";
import {
  arrowBackCircle,
  arrowForwardCircle,
  checkmarkCircle,
  closeCircle,
} from "ionicons/icons";
import stripeLogo from "../../assets/logo/stripe_logo.png";
import HomeContext from "../../contexts/home.ctx";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import BaseButton from "../@Base/BaseButton/BaseButton";
import routes from "../../routes";
import handPointer from "../../assets/images/handPointer.png";
import { constants } from "../../utils/constants";

const SubscriptionInfoLayout: React.FC = (props) => {
  const homeCtx = useContext(HomeContext);
  const subOrderCtx = useContext(SubscriptionOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  return (
    <div className="subscription-info-layout__container">
      <div className="subscription-info-layout__mobile-container">
        <div className="subscription-info-layout__header-container">
          <div>
            <div className="subscription-info-layout__header-left">
              <span className={"subscription-info-layout__header-text-price"}>
                Inscription gratuite{" "}
              </span>
              <span className={"subscription-info-layout__header-text-normal"}>
                + 15% par commande
              </span>
            </div>
          </div>
        </div>
        <div className="subscription-info-layout__header-container">
          <div className="subscription-info-layout__feature-text">
            Prise de commande sur tous vos appareils
          </div>
          <IonIcon
            className="subscription-info-layout__feature-icon"
            icon={checkmarkCircle}
            color={"success"}
          />
        </div>
        <div className="subscription-info-layout__header-container">
          <div className="subscription-info-layout__feature-text">
            Gestion de commande sur tous vos appareils en cuisine ou en atelier
          </div>
          <IonIcon
            className="subscription-info-layout__feature-icon"
            icon={checkmarkCircle}
            color={"success"}
            size={"2rem"}
          />
        </div>
        <div className="subscription-info-layout__header-container">
          <div className="subscription-info-layout__feature-text">
            Impression de bons au propre. (nécessite une imprimante avec
            Bluetooth)
          </div>
          <IonIcon
            className="subscription-info-layout__feature-icon"
            icon={checkmarkCircle}
            color={"success"}
          />
        </div>
        <div className="subscription-info-layout__header-container">
          <div className="subscription-info-layout__feature-text">
            Commande en ligne. Permettez à vos clients de commander en ligne.
          </div>
          <IonIcon
            className="subscription-info-layout__feature-icon"
            icon={checkmarkCircle}
            color={"success"}
          />
        </div>
        <div className="subscription-info-layout__header-container">
          <div className="subscription-info-layout__feature-text">
            Click&collect. Gérez facilement le click&collect.
          </div>
          <IonIcon
            className="subscription-info-layout__feature-icon"
            icon={checkmarkCircle}
            color={"success"}
          />
        </div>
        <div className="subscription-info-layout__header-container">
          <div className="subscription-info-layout__feature-text">
            Gestion de la livraison. Gérez facilement la livraison sans
            comissions.
          </div>
          <IonIcon
            className="subscription-info-layout__feature-icon"
            icon={checkmarkCircle}
            color={"success"}
          />
        </div>
        <div className="subscription-info-layout__header-container">
          <div className="subscription-info-layout__feature-text">
            Marketing inclus
          </div>
          <IonIcon
            className="subscription-info-layout__feature-icon"
            icon={checkmarkCircle}
            color={"success"}
          />
        </div>
      </div>
      <div className="subscription-info-layout__desktop-container">
        <table className="subscription-info-layout__desktop-table-container">
          <thead className="subscription-info-layout__desktop-table-head-container">
            <tr className="subscription-info-layout__desktop-table-head-row-container">
              <th className="subscription-info-layout__desktop-table-head-item-left">
                Fonctionnalités
              </th>
              <th className="subscription-info-layout__desktop-table-head-item-basic">
                <div className="subscription-info-layout__header-left">
                  <span className="subscription-info-layout__header-text-price">
                    Inscription Gratuite +{" "}
                  </span>
                  <span className="subscription-info-layout__header-text-normal">
                    15 % par commande
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="subscription-info-layout__desktop-table-row-item">
                <strong>Prise de commande</strong> sur tous vos appareils
              </td>
              <td className="subscription-info-layout__desktop-table-row-item-logo-basic">
                <IonIcon
                  className="subscription-info-layout__feature-icon"
                  icon={checkmarkCircle}
                  color={"success"}
                />
              </td>
            </tr>
            <tr>
              <td className="subscription-info-layout__desktop-table-row-item">
                <strong>Gestion de commande</strong> sur tous vos appareils en
                cuisine ou en atelier
              </td>
              <td className="subscription-info-layout__desktop-table-row-item-logo-basic">
                <IonIcon
                  className="subscription-info-layout__feature-icon"
                  icon={checkmarkCircle}
                  color={"success"}
                />
              </td>
            </tr>
            <tr>
              <td className="subscription-info-layout__desktop-table-row-item">
                <strong>Impression de bons au propre</strong>. (nécessite une
                imprimante avec Bluetooth)
              </td>
              <td className="subscription-info-layout__desktop-table-row-item-logo-basic">
                <IonIcon
                  className="subscription-info-layout__feature-icon"
                  icon={checkmarkCircle}
                  color={"success"}
                />
              </td>
            </tr>
            <tr>
              <td className="subscription-info-layout__desktop-table-row-item">
                <strong>Commande en ligne.</strong> Permettez à vos clients de
                commander en ligne.
              </td>

              <td className="subscription-info-layout__desktop-table-row-item-logo-basic">
                <IonIcon
                  className="subscription-info-layout__feature-icon"
                  icon={checkmarkCircle}
                  color={"success"}
                />
              </td>
            </tr>
            <tr>
              <td className="subscription-info-layout__desktop-table-row-item">
                <strong>Gestion de la livraison.</strong> Gérez facilement la
                livraison sans comissions.
              </td>

              <td className="subscription-info-layout__desktop-table-row-item-logo-basic">
                <IonIcon
                  className="subscription-info-layout__feature-icon"
                  icon={checkmarkCircle}
                  color={"success"}
                />
              </td>
            </tr>
            <tr>
              <td className="subscription-info-layout__desktop-table-row-item">
                <strong> Click&collect. </strong> Gérez facilement le
                click&collect.
              </td>

              <td className="subscription-info-layout__desktop-table-row-item-logo-basic">
                <IonIcon
                  className="subscription-info-layout__feature-icon"
                  icon={checkmarkCircle}
                  color={"success"}
                />
              </td>
            </tr>
            <tr>
              <td className="subscription-info-layout__desktop-table-row-item">
                <strong>Marketing inclus</strong>
              </td>

              <td className="subscription-info-layout__desktop-table-row-item-logo-premium__end">
                <IonIcon
                  className="subscription-info-layout__feature-icon"
                  icon={checkmarkCircle}
                  color={"success"}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="subscription-info-layout__desktop-table-row-item-button">
                <BaseButton
                  expand
                  type="submit"
                  onClick={() => {
                    if (authCtx.authState === AuthStates.AUTH) {
                      history.push(routes.ACCOUNT_PAGE);
                    } else {
                      history.push(routes.SIGN_UP_PAGE);
                    }
                  }}
                >
                  S'inscrire
                </BaseButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubscriptionInfoLayout;
