import React, { useContext, useEffect, useState } from "react";
import { IonIcon, IonImg, useIonModal } from "@ionic/react";
import AppLoading from "../@Shared/AppLoading/AppLoading";
import { helpCircle } from "ionicons/icons";
import AuthContext, {
  AuthStates,
  SubscriptionPlan,
} from "../../contexts/auth.ctx";
import CheckoutForm from "../Checkout/CheckoutForm";
import "./SubscribeToPlanComponent.css";
import StripePayment from "../../assets/images/logo-stripe.png";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Redirect, useHistory } from "react-router";
import routes from "../../routes";
import CouponModal from "./CouponModal/CouponModal";
import BaseButton from "../@Base/BaseButton/BaseButton";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import { constants } from "../../utils/constants";
const { gtag, install } = require("ga-gtag");

const stripePromise = loadStripe(constants.STRIPE_PUBLIC_KEY);

interface subscribeProps {
  subscriptionPlan: SubscriptionPlan;
}
const SubscribeToPlanComponent: React.FC<subscribeProps> = (props) => {
  const authCtx = useContext(AuthContext);
  const subOrderCtx = useContext(SubscriptionOrderContext);
  const history = useHistory();
  const [present, dismiss] = useIonModal(CouponModal, {
    subscriptionType: props.subscriptionPlan,
    subOrderCtx: subOrderCtx,
    authCtx: authCtx,
    history: history,
    onDismiss: () => {
      dismiss();
    },
  });

  useEffect(() => {
    gtag("event", "begin_checkout", {
      payment_type: "Credit Card",
      items: [
        {
          item_id: props.subscriptionPlan,
          item_name: props.subscriptionPlan,
          currency: "EUR",
          price: props.subscriptionPlan == "PREMIUM" ? 82.8 : 70.8,
          quantity: 1,
        },
      ],
    });
  }, []);

  if (authCtx.authState == AuthStates.NOT_INIT) {
    return <AppLoading />;
  } else if (authCtx.authState == AuthStates.NOT_AUTH) {
    return <Redirect exact to={routes.START_PAGE} />;
  } else if (authCtx.userRole !== "RESTAURANT") {
    return (
      <div
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        Veuillez vous connecter avec un compte restaurateur avant de pouvoir
        vous abonner svp
        <div className="account__signout-container">
          <BaseButton
            expand
            type="submit"
            outline
            margins="16px 0"
            onClick={() => {
              history.push(routes.START_PAGE);
            }}
          >
            Se connecter
          </BaseButton>
        </div>
      </div>
    );
  } else if (authCtx.subscriptionPlan !== "NONE") {
    return (
      <>
        <div className="subscribe-form_container">Vous êtes déjà abonné</div>
        <div
          className="subscribe-form__go-back__container"
          onClick={() => history.push(routes.HOME_PAGE)}
        >
          <div className="subscribe-form__go-back__button">Retour</div>
        </div>
      </>
    );
  }
  return (
    <div className="subscribe-form_container">
      <div className="subscribe-form_main-container">
        <div className="subscribe-form_order-resume-container">
          <div className="subscribe-form_order-resume-header">
            Résumé de votre commande
          </div>

          <div className="subscribe-form_order-row">
            <div className="subscribe-form_order-row-header">
              Abonnement{" "}
              {props.subscriptionPlan == "BASIC" ? (
                "Basic"
              ) : (
                <div className="subscribe-form_order-row-header-premium">
                  {props.subscriptionPlan == "PREMIUM_WITH_MATERIAL"
                    ? "Pro"
                    : "Premium"}
                </div>
              )}
            </div>
            <div className="subscribe-form_order-row-text">
              Nombre d'appareils illimités
            </div>
            {props.subscriptionPlan == "BASIC" ? null : (
              <div className="subscribe-form_order-row-text">
                Compte livreurs illimités
              </div>
            )}

            <div className="subscribe-form_order-row-text">
              1 commerce par abonnement
            </div>
          </div>

          <div className="subscribe-form_order-row">
            <div className="subscribe-form_order-row-header">
              TOTAL :{" "}
              {props.subscriptionPlan == "BASIC" ? (
                <div className="subscribe-form_order-row-header-premium">
                  69{constants.CURRENCY}{" "}
                </div>
              ) : (
                <>
                  <div className="subscribe-form_order-row-header-premium">
                    {props.subscriptionPlan == "PREMIUM_WITH_MATERIAL"
                      ? ` 129${constants.CURRENCY}`
                      : ` 99${constants.CURRENCY} `}
                  </div>
                </>
              )}{" "}
              /mois
            </div>
          </div>

          <div className="subscribe-form_order-fees-and-commitment">
            <div className="subscribe-form_order-fees-and-commitment-left">
              <div className="subscribe-form_order-row-text">
                Sans engagement
              </div>
            </div>
            {props.subscriptionPlan == "PREMIUM" ? (
              <div className="subscribe-form_order-fees-and-commitment-right">
                <div className="subscribe-form_order-fees-and-commitment-right-container">
                  (Hors frais bancaire)
                  <IonIcon
                    icon={helpCircle}
                    className="subscribe-form_order-fees-and-commitment-right-icon"
                    onClick={() => {
                      history.push(routes.FEES);
                      // homeCtx.currentHomePageChangeHandler("FEES");
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="subscribe-form_order-row">
            <div className="subscribe-form_order-row-header">
              TOTAL :{" "}
              {props.subscriptionPlan == "BASIC" ? (
                <div className="subscribe-form_order-row-header-premium">
                  69{constants.CURRENCY}{" "}
                </div>
              ) : (
                <>
                  <div className="subscribe-form_order-row-header-premium">
                    {props.subscriptionPlan == "PREMIUM_WITH_MATERIAL"
                      ? ` 129${constants.CURRENCY}`
                      : ` 99${constants.CURRENCY} `}
                  </div>
                </>
              )}{" "}
              /mois
            </div>
          </div>
        </div>
        <div className="subscribe-form_order__button-coupon">
          <BaseButton
            expand
            onClick={() => {
              present();
            }}
          >
            J'ai un code promo !
          </BaseButton>
        </div>
      </div>
      <div className="subscribe-form__form__checkout">
        <IonImg src={StripePayment} data-testid="logo__ion-img" />
        <>
          <Elements
            options={{
              appearance: {
                theme: "stripe",
              },
            }}
            stripe={stripePromise}
          >
            <CheckoutForm
              subscriptionPlan={
                props.subscriptionPlan == "BASIC"
                  ? "BASIC"
                  : props.subscriptionPlan == "PREMIUM_WITH_MATERIAL"
                  ? "PREMIUM_WITH_MATERIAL"
                  : "PREMIUM"
              }
            />
          </Elements>
        </>
      </div>
    </div>
  );
};

export default SubscribeToPlanComponent;
