import "./StartChoices.css";
import routes from "../../../routes";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import Logo from "../../@Shared/Logo/Logo";

interface ContainerProps {}

export const signUpButtonRoute = routes.SIGN_UP_PAGE;
export const signInButtonRoute = routes.SIGN_IN_PAGE;
export const homeButtonRoute = routes.HOME_PAGE;

const StartChoices: React.FC<ContainerProps> = (props) => {
  return (
    <>
      <header className="start-logo" data-testid="start-logo">
        <Logo size="base" />
      </header>
      <section className="start-choices" data-testid="start-choices">
        <BaseButton big routerLink={signInButtonRoute} expand>
          Connexion
        </BaseButton>
        <BaseButton big routerLink={signUpButtonRoute} expand outline>
          Inscription
        </BaseButton>
      </section>
      <section className="home-back-container" >
      <BaseButton big routerLink={homeButtonRoute} expand outline>
          Accueil
        </BaseButton>
        </section>
    </>
  );
};

export default StartChoices;
