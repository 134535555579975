import SignInForm from "../../../components/Start/SignInForm/SignInForm";
import { SignInContextProvider } from "../../../contexts/sign-in.ctx";
import StartLayout, {
  StartLayoutDispositions,
} from "../../../components/Layouts/StartLayout/StartLayout";

const SignInPage: React.FC = () => {
  return (
    <SignInContextProvider>
      <StartLayout disposition={StartLayoutDispositions.SIGNIN}>
        <SignInForm />
      </StartLayout>
    </SignInContextProvider>
  );
};

export default SignInPage;
