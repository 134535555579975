import { useContext, useEffect, useState } from "react";
import { IonContent, IonPage, useIonAlert } from "@ionic/react";
import { Redirect } from "react-router";
import AppLoading from "../../components/@Shared/AppLoading/AppLoading";
import StartChoices from "../../components/Start/StartChoices/StartChoices";
import StartLayout, {
  StartLayoutDispositions,
} from "../../components/Layouts/StartLayout/StartLayout";
import "./Admin.css";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import BaseTextInput from "../@Base/BaseTextInput/BaseTextInput";
import BaseButton from "../@Base/BaseButton/BaseButton";
import { adminService } from "../../services";

import routes from "../../routes";
import NavBar from "../NavBar/NavBar";

const devAdmin = "UvrV0DHpLUOmWxfJePzmuWExVzU2";
const prodAdmin = "TKr2cs7tFBTw4P9KFAXlowCXf4S2";

const Admin: React.FC = () => {
  const authCtx = useContext(AuthContext);

  const [code, setCode] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [timeLengthInMonth, setTimeLengthInMonth] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [present, dismiss] = useIonAlert();
  const [rtListenersList, setRtListenersList] = useState([] as Function[]);

  const handleCreateCoupon = () => {
    setIsLoading(true);
    adminService
      .generateCoupon(timeLengthInMonth, code)
      .then(() => {
        setIsLoading(false);
        setCode("");
        setTimeLengthInMonth(1);
        present({
          header: "Succès",
          message: "Votre coupon a été crée avec succès !",
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            dismiss();
          },
        });
      })
      .catch((err) => {
        console.log(err);
        present({
          header: "Erreur",
          message: err.response
            ? err.response.data
              ? err.response.data.error
                ? "Erreur : " + err.response.data.error
                : "Une erreur est survenue."
              : "Une erreur est survenue."
            : "Une erreur est survenue.",
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            dismiss();
          },
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const couponsListener: Function = adminService.listenToCoupons(
      (coupons: any) => {
        setCoupons(coupons);
      }
    );
    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([couponsListener]);
  }, []);

  return (
    <>
      <div className="home-admin__main-container">
        {authCtx.authState == AuthStates.NOT_INIT ? (
          <AppLoading />
        ) : authCtx.authState == AuthStates.NOT_AUTH ? (
          <Redirect exact to={routes.START_PAGE} />
        ) : authCtx.authState == AuthStates.AUTH &&
          (authCtx.userId == devAdmin || authCtx.userId == prodAdmin) ? (
          <div className="home-admin__container">
            {" "}
            <div>Attention création de coupon GRATUIT uniquement</div>
            <BaseTextInput
              label="Durée du coupon EN MOIS"
              controller={{
                value: timeLengthInMonth,
                onChange: (event: any) => {
                  setTimeLengthInMonth(event.target.value.replace(/\D/g, ""));
                },
              }}
            />
            <BaseTextInput
              label="Code"
              controller={{
                value: code,
                onChange: (event: any) => {
                  setCode(event.target.value);
                },
              }}
            />
            <BaseButton
              expand
              type="submit"
              isLoading={isLoading}
              onClick={() => {
                handleCreateCoupon();
              }}
            >
              Confirmer
            </BaseButton>
            <div className="home-admin__coupon-container">
              {coupons.map((coupon: any) => {
                return (
                  <div key={coupon.code} className="home-admin__coupon-item">
                    {coupon.code}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Redirect exact to={routes.HOME_PAGE} />
        )}
      </div>
    </>
  );
};

export default Admin;
