import React from "react";
import { IonContent, IonImg, IonPage, IonIcon } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { checkmarkCircleOutline, videocam } from "ionicons/icons";
import { Redirect, useHistory } from "react-router";
import NavBar from "../../components/NavBar/NavBar";
import SubscribeToPlanComponent from "../../components/Subscribe/SubscribeToPlanComponent";
import SubscriptionSuccess from "../../components/Success/SubscriptionSuccess";
import Account from "../../components/Account/Account";
import ChooseSubscriptionPlan from "../../components/ChooseSubscripionPlan/ChooseSubscriptionPlan";
import HomeContext from "../../contexts/home.ctx";
import Footer from "../../components/Footer/Footer";
import AuthContext, {
  AuthStates,
  SubscriptionPlan,
} from "../../contexts/auth.ctx";
import "./StripeAccountSuccess.css";
import routes from "../../routes";
import BaseButton from "../../components/@Base/BaseButton/BaseButton";
import AppLoading from "../../components/@Shared/AppLoading/AppLoading";

const StripeAccountSuccess: React.FC = (props) => {
  const homeCtx = useContext(HomeContext);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    authCtx.getUserData();
  }, []);
  if (authCtx.authState == AuthStates.NOT_AUTH) {
    return <Redirect to={routes.START_PAGE} />;
  } else if (authCtx.authState == AuthStates.NOT_INIT) {
    return <AppLoading />;
  }
  return (
    <div className="stripe-account-success-container">
      <div className="stripe-account-success__success-text">
        Merci d'avoir complété votre profil
      </div>
      <div className="stripe-account-success__success-text">
        <IonIcon
          className="stripe-account-success__success-icon"
          icon={checkmarkCircleOutline}
          color={"success"}
        />
      </div>

      <div className="stripe-account-success__text-info-container">
        <div className="stripe-account-success__text-info">
          {" "}
          Vous pouvez maintenant profiter de toutes nos fonctionnalités.
        </div>
        <br />
        <div className="stripe-account-success__text-info">
          {" "}
          Vous allez pouvoir créer votre carte, et commencer à utiliser iziUP !{" "}
        </div>
        <br />
        <div className="stripe-account-success__text-info">
          {" "}
          Voici une petite video qui vous apprendra à utiliser et profiter du
          potentiel maximum de iziUP !{" "}
        </div>
        <br />
      </div>
      <div className="stripe-account-success__button-main">
        <BaseButton
          expand
          type="submit"
          isLoading={authCtx.isLoading}
          onClick={() => {
            history.push(routes.VIDEO);
          }}
        >
          <div className="stripe-account-success__button-container">
            Voir la video
            <IonIcon
              className="stripe-account-success__button-icon"
              icon={videocam}
            />
          </div>
        </BaseButton>
      </div>
    </div>
  );
};

export default StripeAccountSuccess;
