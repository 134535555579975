import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { IonIcon, IonImg } from "@ionic/react";
import {
  close,
  menu,
  person,
  videocam,
  help,
  chatbubble,
} from "ionicons/icons";
import "./HomeMainContent.css";
import AuthContext, { AuthStates } from "../../../contexts/auth.ctx";
import HomeContext from "../../../contexts/home.ctx";
import homme1 from "../../../assets/images/homme1.png";
import homme2 from "../../../assets/images/homme2.png";
import femme1 from "../../../assets/images/femme1.png";
import tickerPrinter from "../../../assets/images/ticket_printer.png";
import tablette1 from "../../../assets/images/tablette1.png";
import tablette2 from "../../../assets/images/tablette2.png";
import tablette3 from "../../../assets/images/tablette3.png";
import CallButton from "../../CallButton/CallButton";
import femme2 from "../../../assets/images/femme2.png";
import mobile1 from "../../../assets/images/mobile1.png";
import mobile2 from "../../../assets/images/mobile2.png";
import screenshot1 from "../../../assets/images/screenshot1.png";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import ChooseSubscriptionPlan from "../../ChooseSubscripionPlan/ChooseSubscriptionPlan";
import routes from "../../../routes";
import AppLoading from "../../@Shared/AppLoading/AppLoading";

interface ContainerProps {}

const ratioThreshold = 0.25;
const observerOptions = {
  root: null,
  rootMargin: "0px",
  threshold: ratioThreshold,
};

const HomeMainContent: React.FC<ContainerProps> = (props) => {
  const authCtx = useContext(AuthContext);
  const homeCtx = useContext(HomeContext);
  const [imgNumbLoaded, setimgNumbLoaded] = useState(0);
  const history = useHistory();
  console.log(imgNumbLoaded);

  const callbackFunction = (entries: any, observer: any) => {
    entries.forEach((entry: any) => {
      if (entry.intersectionRatio > ratioThreshold) {
        console.log("visible");
        observer.unobserve(entry.target);
        entry.target.classList.add("home-main-content__reveal-visible");
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      callbackFunction,
      observerOptions
    );
    document.querySelectorAll(".home-main-content__reveal").forEach((doc) => {
      observer.observe(doc);
    });
  }, []);

  return (
    <div className="home-main-content__main-container">
      <div className="home-main-content__row-container">
        <div className="home-main-content__text-container">
          <div className="home-main-content__text-bold">
            Application de prise et de gestion de commandes
          </div>

          <div className="home-main-content__text-simple">
            <strong>Ajoutez les éléments </strong> qui composent votre carte en
            2 clics .{" "}
          </div>
          <div className="home-main-content__text-simple">
            {" "}
            <strong> Prenez vos commandes </strong> sur tous vos appareils.{" "}
          </div>
          <div className="home-main-content__text-simple">
            {" "}
            <strong>
              {" "}
              L’application organise pour vous le Click & Collect et la
              livraison{" "}
            </strong>{" "}
          </div>
        </div>

        <div className="home-main-content__img-container">
          <div className="home-main-content__img-container-row">
            <div className="home-main-content__img-medium">
              <IonImg
                src={tablette1}
                // className="home-main-content__img-medium"
              />
            </div>
            <div className="home-main-content__img-small--printer">
              <IonImg
                src={tickerPrinter}

                // className="home-main-content__img-small--printer"
              />
            </div>
          </div>
          <div className="home-main-content__call-button-container">
            <CallButton />
          </div>
        </div>
      </div>

      <div className="home-main-content__row-container-reverse home-main-content__reveal">
        <div className="home-main-content__img-container home-main-content__reveal-2">
          <div className="home-main-content__img-big">
            <IonImg
              src={tablette2}
              // className="home-main-content__img-big"
              onLoad={() => setimgNumbLoaded(imgNumbLoaded + 1)}
            />
          </div>
        </div>
        <div className="home-main-content__text-container home-main-content__reveal-1">
          <div className="home-main-content__text-bold">
            <div className="home-main-content__text-bold-color">Gestion</div> de
            commandes
          </div>
          <div className="home-main-content__text-simple">
            Réceptionnez vos commandes <strong>sur tous vos appareils.</strong>
          </div>
          <div className="home-main-content__text-simple">
            {" "}
            D’un coup d’œil, suivez la progression et le détail de vos commandes
          </div>
        </div>
      </div>

      <div className="home-main-content__row-container home-main-content__reveal">
        <div className="home-main-content__text-container home-main-content__reveal-1">
          <div className="home-main-content__text-bold">
            Commandes{" "}
            <div className="home-main-content__text-bold-color"> en ligne </div>
          </div>
          <div className="home-main-content__text-simple">
            Proposez la commande en ligne et le paiement par carte bancaire à
            vos clients
          </div>
          <div className="home-main-content__text-simple">
            <strong> Aucune commission à la commande ou à la livraison </strong>{" "}
            *(hors frais bancaires)
          </div>
          <div className="home-main-content__button-center">
            <BaseButton
              expand
              type="submit"
              showMouse={true}
              onClick={() => {
                // homeCtx.currentHomePageChangeHandler("CHOOSE_SUBSCRIPTION_PLAN");
                history.push(routes.CHOOSE_SUBSCRIPTION_PLAN);
              }}
            >
              Tarifs et abonnements
            </BaseButton>
          </div>
        </div>
        <div className="home-main-content__img-container home-main-content__reveal-2">
          <div className="home-main-content__img-container-row">
            <div className="home-main-content__img-absolute">
              <IonImg
                onLoad={() => setimgNumbLoaded(imgNumbLoaded + 1)}
                src={mobile1}
                // className="home-main-content__img-absolute"
              />
            </div>
            <div className="home-main-content__img-big">
              <IonImg
                onLoad={() => setimgNumbLoaded(imgNumbLoaded + 1)}
                src={femme2}
                // className="home-main-content__img-big"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="home-main-content__row-container-reverse home-main-content__reveal">
        <div className="home-main-content__img-container home-main-content__reveal-2">
          <div className="home-main-content__img-big">
            <IonImg
              onLoad={() => setimgNumbLoaded(imgNumbLoaded + 1)}
              src={tablette3}
              // className="home-main-content__img-big"
            />
          </div>
        </div>
        <div className="home-main-content__text-container home-main-content__reveal-1">
          <div className="home-main-content__text-bold">
            Paramétrage{" "}
            <div className="home-main-content__text-bold-color">
              {" "}
              simple et rapide{" "}
            </div>
          </div>
          <div className="home-main-content__text-simple-center">
            <strong>En 2 clics vous paramétrez :</strong>
            <ul className="home-main-content__list-container">
              <li>Votre carte en ligne</li>
              <li>Vos délais de livraison</li>
              <li>Votre zone de livraison</li>
              <li>Vos coûts de livraison</li>
              <li>Vos horaires d'ouverture pour le Click&collect.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="home-main-content__row-container home-main-content__reveal">
        <div className="home-main-content__text-container home-main-content__reveal-1">
          <div className="home-main-content__text-bold">
            Gérez la{" "}
            <div className="home-main-content__text-bold-color">
              {" "}
              livraison{" "}
            </div>{" "}
            vous meme{" "}
            <div className="home-main-content__text-bold-color">
              {" "}
              en 2 clics{" "}
            </div>
          </div>
          <div className="home-main-content__text-simple">
            Vos livreurs se créent un compte <strong>GRATUIT</strong> sur
            IziUp.fr
          </div>
          <div className="home-main-content__text-simple">
            Validez leurs inscriptions et démarrez la livraison
          </div>
          <div className="home-main-content__text-simple">
            Vos livreurs seront opérationnels rapidement et bénéficieront de
            fonctionnalités <strong> simples et efficaces. </strong>
          </div>
          <div className="home-main-content__text-simple-center">
            <strong> GAIN DE TEMPS GARANTI</strong>
          </div>
        </div>
        <div className="home-main-content__img-container home-main-content__reveal-2">
          <div className="home-main-content__img-container-row">
            <div className="home-main-content__img-mobile-phone">
              <IonImg
                onLoad={() => setimgNumbLoaded(imgNumbLoaded + 1)}
                src={mobile1}
                // className="home-main-content__img-mobile-phone"
              />
            </div>
            <div className="home-main-content__img-mobile-phone">
              <IonImg
                onLoad={() => setimgNumbLoaded(imgNumbLoaded + 1)}
                src={mobile2}
                className="home-main-content__img-mobile-phone"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="home-main-content__column-container home-main-content__reveal">
        <div className="home-main-content__text-bold-special home-main-content__reveal-1">
          Un gain de temps et une sérénité à tous les postes.
        </div>

        <div className="home-main-content__row-container home-main-content__reveal">
          <div className="home-main-content__text-container home-main-content__reveal-1">
            <div className="home-main-content__text-simple">
              Réception instantanée des commandes en cuisine sur tablette et
              impression de bon(s) de commande(s).
            </div>
            <div className="home-main-content__text-simple">
              Une intégration rapide de vos nouveaux collaborateurs.
            </div>
            <div className="home-main-content__text-simple">
              Une interface simple et intuitive.
            </div>
            <div className="home-main-content__text-simple">
              S'adapte à tous vos équipements.{" "}
            </div>
            <div className="home-main-content__text-simple-center">
              <strong>(Windows - MAC - IOS - Android ) </strong>
            </div>
          </div>
          <div className="home-main-content__img-container home-main-content__reveal-2">
            <div className="home-main-content__img-overlay-container">
              <div className="home-main-content__img-big">
                <IonImg
                  onLoad={() => setimgNumbLoaded(imgNumbLoaded + 1)}
                  src={screenshot1}
                  className="home-main-content__img-big"
                />
              </div>
              <div className="home-main-content__img-overlay">
                une prise d'information client rapide!
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-main-content__column-container home-main-content__reveal">
        <div className="home-main-content__column-container home-main-content__reveal-1">
          <ChooseSubscriptionPlan />
        </div>
      </div>
    </div>
  );
};

export default HomeMainContent;
