import React, { useEffect, useState } from "react";
import API from "../services";
import { $firebaseAuth } from "../services/firebase-service";
import { useIonAlert } from "@ionic/react";
import authServices from "../services/auth-service";
// import { customerData } from "../types";
import axios from "axios";
import buildEnv from "../build.env.json";

export enum AuthStates {
  "AUTH",
  "NOT_AUTH",
  "NOT_INIT",
}

export type SubscriptionPlan =
  | "BASIC"
  | "PREMIUM"
  | "PREMIUM_WITH_MATERIAL"
  | "NONE";

const AuthContext = React.createContext({
  authState: AuthStates.NOT_INIT,
  role: "",
  userRole: "",
  restaurantId: "",
  userId: "",
  userEmail: "",
  subscriptionPlan: "NONE" as SubscriptionPlan,
  userStripeAccountActivated: false,
  userStripeAccountId: "",
  userStripeInfoRequired: true,
  userStripeLinkUrl: "",
  userStripeSubscriptionActive: false,
  isLoading: false,
  userStripeAccountDashboardLink: "",
  // userData: {} as customerData,
  signOut: () => {},
  getNewStripeAccountLink: () => {},
  getUserData: () => {},
  authStateHandler: (authState: AuthStates) => {},
  // updateUserData: (data: customerData) => {},
});

export const AuthContextProvider: React.FC = (props) => {
  const [authState, setAuthState] = useState<AuthStates>(AuthStates.NOT_INIT);
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] =
    useState<SubscriptionPlan>("NONE");
  const [userStripeAccountDashboardLink, setUserStripeAccountDashboardLink] =
    useState("");
  const [userStripeLinkUrl, setUserStripeLinkUrl] = useState("");
  const [userStripeInfoRequired, setUserStripeInfoRequired] = useState(true);
  const [userStripeSubscriptionActive, setUserStripeSubscriptionActive] =
    useState(false);
  const [userStripeAccountActivated, setUserStripeAccountActivated] =
    useState(false);
  const [userStripeAccountId, setUserStripeAccountId] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [userId, setUserId] = useState("");
  const [present, dismiss] = useIonAlert();
  // const [userData, setUserData] = useState({} as customerData);
  const [userEmail, setUserEmail] = useState("");
  const [rtListenersList, setRtListenersList] = useState(Function);

  // TODO : Add a props for premium user status
  // const updateUserData = (data: customerData) => {
  //   authServices
  //     .updateUserData(data)
  //     .then(() => {
  //       setUserData(data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const authStateHandler = (authState: AuthStates) => {
    setAuthState(authState);
  };
  useEffect(() => {
    const authStateChangedUnsubscribe = $firebaseAuth.onAuthStateChanged(
      (user) => {
        if (!user) {
          setAuthState(AuthStates.NOT_AUTH);
          setRole("");
          setUserRole("");
          setRestaurantId("");
          setUserId("");
          setSubscriptionPlan("NONE");
          setUserStripeAccountActivated(false);
          setUserStripeSubscriptionActive(false);
          setUserStripeAccountId("");
          setUserStripeInfoRequired(true);
        } else {
          user.getIdTokenResult().then((token) => {
            if (token.claims.user_role === "RESTAURANT") {
              setRole(token.claims.role);
              setUserRole(token.claims.user_role);
              setRestaurantId(token.claims.restaurant_id);
              setUserId(user.uid);
              setUserEmail(user.email || "");
              getUserData()
                .then(() => {
                  setAuthState(AuthStates.AUTH);
                })
                .catch((err) => {
                  setAuthState(AuthStates.AUTH);
                });
              const authenticatedListener: Function =
                authServices.listenToAuthenticatedUser(
                  user.uid,
                  (userData: any) => {
                    setUserStripeAccountActivated(
                      userData.stripe_account_active
                    );
                    setUserStripeSubscriptionActive(
                      userData.subscription_plan_active
                    );
                    setUserStripeAccountId(userData.stripe_account_id);
                    setSubscriptionPlan(
                      userData.subscription_plan
                        ? userData.subscription_plan
                        : "NONE"
                    );
                    setUserStripeInfoRequired(userData.stripe_infos_required);
                    setUserStripeLinkUrl(
                      userData.stripe_account_link
                        ? userData.stripe_account_link
                        : ""
                    );
                  }
                );

              setRtListenersList(authenticatedListener);
            } else {
              setAuthState(AuthStates.AUTH);
              setRole(token.claims.role);
              setUserRole(token.claims.user_role);
              setUserId(user.uid);
              setUserEmail(user.email || "");
            }

            // authServices
            //   .getAuthenticatedUserData()
            //   .then((userData: customerData) => {
            //     console.log(userData);
            //     setUserData(userData);
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
            // TODO : Set the user premium status
          });
        }
      }
    );
    return () => authStateChangedUnsubscribe();
  }, []);

  const getUserData = async () => {
    setIsLoading(true);
    return authServices
      .getAuthenticatedUserData()
      .then((userData) => {
        setUserStripeAccountActivated(userData.stripe_account_active);
        setUserStripeSubscriptionActive(userData.subscription_plan_active);
        setUserStripeAccountId(userData.stripe_account_id);
        setSubscriptionPlan(
          userData.subscription_plan ? userData.subscription_plan : "NONE"
        );
        setUserStripeInfoRequired(userData.stripe_infos_required);
        setUserStripeLinkUrl(
          userData.stripe_account_link ? userData.stripe_account_link : ""
        );

        setUserStripeAccountDashboardLink(
          userData.stripe_account_dashboard_link
            ? userData.stripe_account_dashboard_link
            : ""
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getNewStripeAccountLink = () => {
    setIsLoading(true);
    authServices
      .getNewStripeAccountLink()
      .then((response) => {
        if (response.url) {
          setUserStripeLinkUrl(response.url);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setUserStripeLinkUrl("");
        present({
          header: "Erreur",
          message: "Une erreur est survenue, veuillez nous excuser",
          buttons: [
            {
              text: "Ok",
              handler: (d) => {
                dismiss();
              },
            },
          ],
          onDidDismiss: (e) => {
            dismiss();
          },
        });
        console.log(err);
        setIsLoading(false);
      });
  };
  const signOutHandler = () => {
    present({
      header: "Déconnexion",
      message: "êtes-vous sûr de vouloir vous déconnecter ?",
      buttons: [
        {
          text: "Se déconnecter",
          handler: (d) => {
            $firebaseAuth.signOut();
            dismiss();
          },
        },
        {
          text: "Annuler",
          handler: (d) => {
            dismiss();
          },
        },
      ],
      onDidDismiss: (e) => {
        dismiss();
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        role,
        userRole,
        restaurantId,
        userId,
        userEmail,
        subscriptionPlan,
        userStripeAccountActivated,
        userStripeAccountId,
        userStripeInfoRequired,
        userStripeLinkUrl,
        userStripeSubscriptionActive,
        isLoading,
        userStripeAccountDashboardLink,
        // userData,
        signOut: signOutHandler,
        getNewStripeAccountLink: getNewStripeAccountLink,
        getUserData: getUserData,
        authStateHandler,
        // updateUserData: updateUserData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
