import React, { useContext } from "react";
import AuthContext, {
  AuthStates,
  SubscriptionPlan,
} from "../../contexts/auth.ctx";
import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import "./Video.css";
import CallButton from "../CallButton/CallButton";



const Video: React.FC = (props) => {
  const subOrderCtx = useContext(SubscriptionOrderContext);
  return (
    <div className="video-main-container">
      <div className="video-header">
      
Premiers pas en video
      </div>
      <video controls className="video-asset">
  <source src="/assets/videos/creationcarte01.mp4" type="video/mp4"/>
Your browser does not support the video tag.
</video>

            <div className="video-footer">
      
      Vous avez besoin d'aider ?
            </div>
            <div className="video-call-button-container">
            <CallButton/>
            </div>
    </div>
  );
};

export default Video;
