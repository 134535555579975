import "./BaseTextInput.css";
import { IonIcon, IonRouterLink } from "@ionic/react";
import { useState } from "react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";

import { HintLink, InputTypes } from "../../../types";

interface ContainerProps {
  label?: string;
  type?: InputTypes;
  hintLink?: HintLink;
  error?: string;
  controller?: any;
}

const BaseTextInput: React.FC<ContainerProps> = (props) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  return (
    <div className="base-text-input" data-testid="base-text-input">
      <label
        className="base-text-input__label"
        data-testid="base-text-input__label"
      >
        {props.label}
      </label>
      <input
        className="base-text-input__input"
        {...props.controller}
        type={passwordVisibility ? "text" : props.type || "text"}
        data-testid="base-text-input__input"
      />
      {props.type == "password" && (
        <IonIcon
          className="base-text-input__ion-icon"
          data-testid="base-text-input__ion-icon"
          onClick={() => setPasswordVisibility(!passwordVisibility)}
          icon={passwordVisibility ? eyeOffOutline : eyeOutline}
        />
      )}

      <span className="base-text-input__error">{props.error}</span>
      {props.hintLink && (
        <IonRouterLink
          className="base-text-input__hint-link"
          routerLink={props.hintLink.href}
        >
          {props.hintLink.value}
        </IonRouterLink>
      )}
    </div>
  );
};

export default BaseTextInput;
