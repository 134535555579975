import React, { useContext, useEffect } from "react";
import AuthContext, {
  AuthStates,
  SubscriptionPlan,
} from "../../contexts/auth.ctx";
import { IonIcon, IonImg } from "@ionic/react";
import { checkmarkCircleOutline, videocam } from "ionicons/icons";
import stripeLogo from "../../assets/logo/stripe_logo.png";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import BaseButton from "../@Base/BaseButton/BaseButton";
import HomeContext from "../../contexts/home.ctx";
import "./SubscriptionSuccess.css";
import { useHistory } from "react-router";
import routes from "../../routes";
import { useLocation } from "react-router";
import { IonSpinner } from "@ionic/react";
interface SubscriptionSuccessProps {}
const SubscriptionSuccess: React.FC<SubscriptionSuccessProps> = (props) => {
  const subOrderCtx = useContext(SubscriptionOrderContext);
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const homeCtx = useContext(HomeContext);
  // const location = useLocation()
  // const queryParameters = new URLSearchParams(location.search);
  const currentPlan =
    authCtx.subscriptionPlan || subOrderCtx.currentSubscriptionPlan;
  const stripeUserLink =
    authCtx.userStripeLinkUrl || subOrderCtx.stripeConnectedAccountUrl;
  useEffect(() => {
    authCtx.getUserData();
  }, []);
  if (authCtx.isLoading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IonSpinner />
      </div>
    );
  }
  return (
    <div className="subscription-success">
      <div className="subscription-success__success-container">
        <span>Félicitation, votre paiement a bien été validé</span>
        <IonIcon
          icon={checkmarkCircleOutline}
          className="subscription-success__success-icon"
        />

        <span className="subscription-success__success-container__total">
          Abonnement {"  "}
          {currentPlan == "BASIC" ? (
            "Basic"
          ) : (
            <div className="subscription-success__success-container__total-price">
              {"  "} Premium
            </div>
          )}
        </span>

        <div className="subscription-success__success-container__text">
          Merci de votre confiance
        </div>
        <div className="">
          {currentPlan == "BASIC" ? (
            <>
              <div className="subscription-success__success-container__text">
                Vous pouvez maintenant profiter de toutes nos fonctionnalités.
              </div>
              <div className="subscription-success__success-container__text">
                Vous allez pouvoir créer votre carte, et commencer à utiliser
                iziUP !
              </div>
            </>
          ) : (
            <>
              <div className="subscription-success__success-container__text">
                Pour démarrer la vente en ligne, veuillez compléter votre profil
                stripe *
              </div>
              <div className="subscription-success__success-container__text--light">
                Vous retrouverez toutes les informations lié à vos paiements en
                ligne, et avoir un aperçu des virements à venir sur votre profil
                STRIPE.
              </div>
              <div className="subscription-success__success-container__text--light">
                Si vous passez cette étape, vous pourrez toujours y revenir à
                tout moment dans l'onglet mon compte
              </div>
              <div className="subscription-success__stripe-button-container">
                <BaseButton
                  expand
                  type="submit"
                  outline
                  onClick={() => {
                    window.open(stripeUserLink, "_blank");
                  }}
                  showMouse={true}
                >
                  <div className="subscription-success__stripe-button">
                    Mon Profil
                    <IonImg
                      src={stripeLogo}
                      className="subscription-success__stripe-button-logo"
                    />
                  </div>
                </BaseButton>
              </div>
            </>
          )}
          <div className="subscription-success__success-container__text">
            Voici une petite video qui vous apprendra à utiliser et profiter du
            potentiel maximum de iziUP !
          </div>
          <div className="subscription-success__stripe-button-container">
            <BaseButton
              expand
              type="submit"
              onClick={() => {
                history.push(routes.VIDEO);
                // homeCtx.currentHomePageChangeHandler("VIDEO");
              }}
            >
              <div className="subscription-success__video-button">
                Voir la video
                <IonIcon
                  src={videocam}
                  className="subscription-success__video-button-logo"
                />
              </div>
            </BaseButton>
          </div>
          {currentPlan == "BASIC" ? null : (
            <div className="subscription-success__success-container__text--light">
              * : Vous retrouverez toutes les informations lié à vos paiements
              en ligne, et avoir un aperçu des virements à venir sur votre
              profil STRIPE.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;
