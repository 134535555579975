export default {
  REQUIRED: "Ce champs est obligatoire.",
  EMAIL: "Ce champs doit être un email valide.",
  MIN_LENGTH: (nb: number) =>
    `Ce champs doit comprendre au moins ${nb} caractères.`,
  MAX_LENGTH: (nb: number) =>
    `Ce champs doit comprendre au maximum ${nb} caractères.`,
  PATTERN: (pattern: number) => `Ce champs doit avoir la forme : ${pattern}.`,
  NOT_THE_SAME: "Les champs ne correspondent pas.",
  SIGNUP_ERROR_ALERT: {
    header: "Impossible de créer un compte",
    detail:
      "Une erreur inconnue est survenue, veuillez réessayer ultérieurement.",
  },
  USER_ALREADY_EXISTS_ERROR_ALERT: {
    header: "Adresse email déjà utilisée",
    detail: "Un utilisateur est déjà enregistré avec cette adresse.",
  },
  SIGNIN_ERROR_ALERT: {
    header: "Impossible de se connecter",
    detail:
      "Une erreur inconnue est survenue, veuillez réessayer ultérieurement.",
  },
  WRONG_USER_PASSWORD_ERROR_ALERT: {
    header: "Le mot de passe est invalide",
    detail: "Veuilles vérifier vos informations de connexion.",
  },
  USER_NOT_FOUND_ERROR_ALERT: {
    header: "Utilisateur introuvable",
    detail: "Veuilles vérifier vos informations de connexion.",
  },
  RESET_PASSWORD_ERROR_ALERT: {
    header: "Impossible de récupérer le mot de passe",
    detail:
      "Une erreur inconnue est survenue, veuillez réessayer ultérieurement.",
  },
  CATEGORY_CREATION_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la création de la catégorie.",
  },
  CATEGORY_UPDATE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la mise à jour de la catégorie.",
  },
  CATEGORY_DELETE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la suppression de la catégorie.",
  },

  PRODUCTS_OPTIONS_CREATION_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail: "Une erreur inconnue est survenue lors de la création des options.",
  },
  PRODUCTS_OPTIONS_UPDATE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la mise à jour des options.",
  },
  PRODUCTS_OPTIONS_DELETE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la suppression des options.",
  },

  PRODUCT_CREATION_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail: "Une erreur inconnue est survenue lors de la création du produit.",
  },
  PRODUCT_UPDATE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la mise à jour du produit.",
  },
  PRODUCT_DELETE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la suppression du produit.",
  },
  CUSTOMER_SEARCH_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail: "Une erreur inconnue est survenue lors de la recherche de clients.",
  },
  CUSTOMER_CREATION_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail: "Une erreur inconnue est survenue lors de la création du client.",
  },
  ORDER_CREATION_RETRIEVE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur de récupération est survenue lors de la création de la commande.",
  },
  ORDER_CREATION_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la création de la commande.",
  },
  ORDER_UPDATE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la mise à jour de la commande.",
  },
  PENDING_COURIER_CREATION_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la demande d'ajout au restaurant.",
  },
  PENDING_COURIER_DELETION_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la suppression de la demande.",
  },
  COURIER_CREATION_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail: "Une erreur inconnue est survenue lors de l'ajout du livreur.",
  },
  COURIER_DELETION_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la suppression du livreur.",
  },
  DELIVERY_CREATION_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la création de la tournée.",
  },
  DELIVERY_UPDATE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la mise à jour de la commande.",
  },
  DELIVERY_CLOSING_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue lors de la cloture de la tournée.",
  },
  WRONG_CREDENTIALS_PASSWORD_UPDATE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail: "L'ancien mot de passe est incorrect, impossible de mettre à jour.",
  },
  PASSWORD_UPDATE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue, impossible de mettre à jour le mot de passe.",
  },
  RESTAURANT_INFOS_UPDATE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue, impossible de mettre à jour les informations du restaurant.",
  },
  USER_INFOS_UPDATE_ERROR_ALERT: {
    header: "Une erreur est survenue",
    detail:
      "Une erreur inconnue est survenue, impossible de mettre à jour les informations.",
  },
  USER_NOT_RESTAURANT_ROLE_ALERT : {
    header: "Seul les restaurateurs peuvent se connecter ici",
    detail:
      "Veuillez vous connecter avec un compte restaurateur",
  },
};
