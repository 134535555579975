export default {
  SIGNUP_SUCCESS_ALERT: {
    header: "Création du compte réussie !",
    detail: "Vous allez être redirigé vers l'écran de connexion.",
  },
  SEND_PASSWORD_RESET_EMAIL_SUCCESS_ALERT: {
    header: "Un email de récupération a été envoyé",
    detail: (email: string) =>
      `Vous allez recevoir à l'adresse ${email} les instructions pour réinitialiser votre mot de passe.`,
  },
  CATEGORY_CREATION_SUCCESS_ALERT: {
    header: "Création réussie !",
    detail: "La nouvelle catégorie a été correctement créée.",
  },
  CATEGORY_UPDATE_SUCCESS_ALERT: {
    header: "Mise à jour réussie !",
    detail: "La catégorie a été correctement mis à jour.",
  },
  CATEGORY_DELETE_SUCCESS_ALERT: {
    header: "Suppression effectuée !",
    detail: "La catégorie a été correctement supprimée.",
  },

  PRODUCTS_OPTIONS_CREATION_SUCCESS_ALERT: {
    header: "Création réussie !",
    detail: "Les nouvelles options ont été correctement créées.",
  },
  PRODUCTS_OPTIONS_UPDATE_SUCCESS_ALERT: {
    header: "Mise à jour réussie !",
    detail: "Les options ont été correctement mises à jour.",
  },
  PRODUCTS_OPTIONS_DELETE_SUCCESS_ALERT: {
    header: "Suppression effectuée !",
    detail: "Les options ont été correctement supprimées.",
  },

  PRODUCT_CREATION_SUCCESS_ALERT: {
    header: "Création réussie !",
    detail: "Le nouveau produit a été correctement créé.",
  },
  PRODUCT_UPDATE_SUCCESS_ALERT: {
    header: "Mise à jour réussie !",
    detail: "Le produit a été correctement mis à jour.",
  },
  PRODUCT_DELETE_SUCCESS_ALERT: {
    header: "Suppression effectuée !",
    detail: "Le produit a été correctement supprimée.",
  },
  CUSTOMER_CREATION_SUCCESS_ALERT: {
    header: "Client ajouté !",
    detail: "Le client a été ajouté avec succès.",
  },
  ORDER_CREATION_SUCCESS_ALERT: {
    header: "Commande ajoutée !",
    detail: "La commande a été enregistrée avec succès.",
  },
  ORDER_UPDATE_SUCCESS_ALERT: {
    header: "Commande mis à jour !",
    detail: "La commande a été mise à jour avec succès.",
  },
  PENDING_COURIER_CREATION_SUCCESS_ALERT: {
    header: "Demande envoyée !",
    detail:
      "La demande a été envoyée, vous devrez vous reconnecter une fois acceptée.",
  },
  PENDING_COURIER_DELETION_SUCCESS_ALERT: {
    header: "Demande supprimée !",
    detail: "La demande a été supprimée avec succès.",
  },
  COURIER_CREATION_SUCCESS_ALERT: {
    header: "Livreur ajouté !",
    detail: "Le livreur a été ajouté avec succès.",
  },
  COURIER_DELETION_SUCCESS_ALERT: {
    header: "Livreur supprimé !",
    detail: "Le livreur a été supprimé avec succès.",
  },
  DELIVERY_CREATION_SUCCESS_ALERT: {
    header: "Tournée créée !",
    detail: "La tournée a été créée avec succès.",
  },
  DELIVERY_UPDATE_SUCCESS_ALERT: {
    header: "Commande mise à jour !",
    detail: "La commande a été correctement mise à jour.",
  },
  DELIVERY_CLOSING_SUCCESS_ALERT: {
    header: "Tournée validée !",
    detail: "La tournée a été validée, vous pouvez créer une nouvelle tournée.",
  },
  PASSWORD_UPDATE_SUCCESS_ALERT: {
    header: "Mot de passe mis à jour !",
    detail: "Le mot de passe a été correctement mis à jour.",
  },
  RESTAURANT_INFOS_UPDATE_SUCCESS_ALERT: {
    header: "Informations mise à jour !",
    detail: "Les informations du restaurant ont été correctement mises à jour.",
  },
  USER_INFOS_UPDATE_SUCCESS_ALERT: {
    header: "Informations mise à jour !",
    detail: "Les informations ont été correctement mises à jour.",
  },
};
