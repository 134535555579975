import React, { useContext, useEffect, useState } from "react";
import signinFormSchema from "../forms/signin.json";
import { ErrorAlert, InputSchema, SignInResponse } from "../types";
import { useIonAlert } from "@ionic/react";
import { useHistory } from "react-router";
import routes from "../routes";
import API from "../services";
import AuthContext, { AuthStates, SubscriptionPlan } from "./auth.ctx";

import authService from "../services/auth-service";

const subscriptionPrices = {
  BASIC: {
    priceHt: 49,
    priceTtc: 70.8,
  },
  PREMIUM: {
    priceHt: 69,
    priceTtc: 82.8,
  },
  PREMIUM_WITH_MATERIAL: {
    priceHt: 129,
    priceTtc: 154.8,
  },
  NONE: {
    priceHt: 0,
    priceTtc: 0,
  },
};

const SubscriptionOrderContext = React.createContext({
  currentSubscriptionPlan: "NONE" as SubscriptionPlan,
  currentSubscriptionPriceHt: 0,
  currentSubscriptionPriceTtc: 0,
  stripeConnectedAccountUrl: "",
  stripeConnectedAccountUrlChangeHandler: (url: string) => {},
  subscriptionChangeHandler: (subscriptionPlan: SubscriptionPlan) => {},
  subscriptionPriceHtChangeHandler: (price: number) => {},
  subscriptionPriceTtcChangeHandler: (price: number) => {},
});

export const SubscriptionOrderContextProvider: React.FC = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan] =
    useState<SubscriptionPlan>("NONE");
  const [currentSubscriptionPriceHt, setCurrentSubscriptionPriceHt] =
    useState(0);
  const [currentSubscriptionPriceTtc, setCurrentSubscriptionPriceTtc] =
    useState(0);
  const [stripeConnectedAccountUrl, setStripeConnectedAccountUrl] =
    useState("");
  // Utility alert method
  const [present] = useIonAlert();

  const subscriptionChangeHandler = (subscriptionPlan: SubscriptionPlan) => {
    console.log("SETTING SUBSCIRPION PLAN TO : ", subscriptionPlan);
    setCurrentSubscriptionPlan(subscriptionPlan);
    setCurrentSubscriptionPriceHt(subscriptionPrices[subscriptionPlan].priceHt);
    setCurrentSubscriptionPriceTtc(
      subscriptionPrices[subscriptionPlan].priceTtc
    );
  };
  const stripeConnectedAccountUrlChangeHandler = (url: string) => {
    setStripeConnectedAccountUrl(url);
  };
  const subscriptionPriceHtChangeHandler = (price: number) => {
    setCurrentSubscriptionPriceHt(price);
  };
  const subscriptionPriceTtcChangeHandler = (price: number) => {
    setCurrentSubscriptionPriceTtc(price);
  };
  return (
    <SubscriptionOrderContext.Provider
      value={{
        currentSubscriptionPlan,
        currentSubscriptionPriceHt,
        currentSubscriptionPriceTtc,
        stripeConnectedAccountUrl,
        stripeConnectedAccountUrlChangeHandler,
        subscriptionChangeHandler,
        subscriptionPriceHtChangeHandler,
        subscriptionPriceTtcChangeHandler,
      }}
    >
      {props.children}
    </SubscriptionOrderContext.Provider>
  );
};

export default SubscriptionOrderContext;
