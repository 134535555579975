import React, { useContext } from "react";
import AuthContext, {
  AuthStates,
  SubscriptionPlan,
} from "../../contexts/auth.ctx";
import { IonIcon, IonImg } from "@ionic/react";
import { checkmarkCircleOutline, videocam, closeCircleOutline } from "ionicons/icons";
import stripeLogo from "../../assets/logo/stripe_logo.png";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import BaseButton from "../@Base/BaseButton/BaseButton";
import HomeContext from "../../contexts/home.ctx";
import "./SubscriptionCanceled.css";

interface SubscriptionCanceledProps {}
const SubscriptionCanceled: React.FC<SubscriptionCanceledProps> = (props) => {
  const subOrderCtx = useContext(SubscriptionOrderContext);
  const authCtx = useContext(AuthContext);
  const homeCtx = useContext(HomeContext);
  return (
    <div className="subscription-canceled">
      <div className="subscription-canceled__success-container">
        <span>Votre abonnement a bien été annulé et sera clos à la fin de la période actuelle</span>
        <IonIcon
          icon={closeCircleOutline}
          className="subscription-canceled__success-icon"
        />

        <span className="subscription-canceled__success-container__total">
          Abonnement {"  "}
          {subOrderCtx.currentSubscriptionPlan == "BASIC" ? (
            "Basic"
          ) : (
            <div className="subscription-canceled__success-container__total-price">
              {"  "} Premium
            </div>
          )}
        </span>

        <div className="subscription-canceled__success-container__text">
          Nous sommes triste de vous voir partir :(
        </div>

      </div>
    </div>
  );
};

export default SubscriptionCanceled;
