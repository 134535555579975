import "./Footer.css";

import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { IonImg, IonIcon } from "@ionic/react";
import {
  arrowBackCircle,
  arrowForwardCircle,
  checkmarkCircle,
  closeCircle,
} from "ionicons/icons";
import stripeLogo from "../../assets/logo/stripe_logo.png";
import HomeContext from "../../contexts/home.ctx";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import BaseButton from "../@Base/BaseButton/BaseButton";
import routes from "../../routes";

const Footer: React.FC = (props) => {
  const homeCtx = useContext(HomeContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const authCtx = useContext(AuthContext);
  const [subscriptionPlan, setSubscriptionPlan] = useState("BASIC");
  const history = useHistory();
  return (
    <div className="footer-container">
      <div
        className="footer-element"
        onClick={() => {
          // homeCtx.lastHomePageChangeHandler(homeCtx.currentHomePage);
          // homeCtx.currentHomePageChangeHandler("CONTACT");
          history.push(routes.CONTACT);
          
        }}
      >
        Contact
      </div>
      <div className="footer-element"
      onClick={() => history.push(routes.FEES)}>Frais de transaction STRIPE</div>
      <div className="footer-element">Mentions légales</div>
      <div className="footer-element" onClick={() => history.push(routes.FAQ)}>Questions fréquentes</div>
      <div className="footer-element">CGV</div>
    </div>
  );
};

export default Footer;
