import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { IonImg, useIonAlert } from "@ionic/react";
import AppLoading from "../@Shared/AppLoading/AppLoading";
import stripeLogo from "../../assets/logo/stripe_logo.png";
import "./Account.css";
import HomeContext from "../../contexts/home.ctx";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import BaseButton from "../@Base/BaseButton/BaseButton";
import routes from "../../routes";
import axios from "axios";
import buildEnv from "../../build.env.json";
import { $firebaseAuth } from "../../services/firebase-service";
import { constants } from "../../utils/constants";
interface ContainerProps {}

const apiEndpoints = {
  cancelSubscription: {
    dev: `${constants.API_URL}/sellerApi/cancelSubscriptionDev`,
    prod: `${constants.API_URL}/sellerApi/cancelSubscription`,
  },
  activateStripeAccount: {
    dev: `${constants.API_URL}/sellerApi/activateRestaurantStripeAccountDev`,
    prod: `${constants.API_URL}/sellerApi/activateRestaurantStripeAccount`,
  },
};

const Account: React.FC = (props) => {
  const homeCtx = useContext(HomeContext);
  const subOrderCtx = useContext(SubscriptionOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  let history = useHistory();
  const authCtx = useContext(AuthContext);
  const [present, dismiss] = useIonAlert();
  const [isLoading, setIsLoading] = useState(false);

  const isUserSubscribed =
    authCtx.subscriptionPlan === "BASIC" ||
    authCtx.subscriptionPlan === "PREMIUM" ||
    authCtx.subscriptionPlan === "PREMIUM_WITH_MATERIAL";

  const handleActivateStripeAccount = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    const apiEndpoint =
      buildEnv.BUILD_ENV == "DEV"
        ? apiEndpoints.activateStripeAccount.dev
        : apiEndpoints.activateStripeAccount.prod;

    $firebaseAuth.currentUser
      ?.getIdToken()
      .then((idToken) => {
        const headers = {
          Authorization: `Bearer ${idToken}`,
        };
        axios
          .post(
            apiEndpoint,
            {},
            {
              headers: headers,
            }
          )
          .then(async (response) => {
            if (response.status == 500) {
              console.log("Error");
            }

            setIsLoading(false);
            console.log(response.data);
            if (response.data?.url) {
              window.open(response.data?.url, "_blank");
              subOrderCtx.stripeConnectedAccountUrlChangeHandler(
                response.data.url
              );
              restaurantDataCtx.initRestaurantData(authCtx.restaurantId);
              authCtx.getUserData();
            } else {
              present({
                header: "Une erreur est survenue",
                message: "Veuillez réessayer",
                buttons: [{ text: "Ok", handler: (d) => {} }],
                onDidDismiss: (e) => {
                  dismiss();
                },
              });
              restaurantDataCtx.initRestaurantData(authCtx.restaurantId);
              authCtx.getUserData();
            }
          })
          .catch((err) => {
            setIsLoading(false);
            present({
              header: "Une erreur est survenue",
              message: "Veuillez réessayer",
              buttons: [{ text: "Ok", handler: (d) => {} }],
              onDidDismiss: (e) => {
                dismiss();
              },
            });
            console.log(err);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        present({
          header: "Une erreur est survenue",
          message: "Veuillez réessayer",
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            dismiss();
          },
        });
        console.log(err);
      });
    // Handle result.error or result.paymentMethod
  };

  if (authCtx.authState == AuthStates.NOT_INIT) {
    return <AppLoading />;
  } else if (authCtx.authState == AuthStates.NOT_AUTH) {
    return (
      <div className="account__container">
        <div className="account__basic-text">
          Veuillez vous connecter pour accéder à la section mon compte
        </div>
        <div className="account__signout-container">
          <BaseButton
            expand
            type="submit"
            outline
            onClick={() => {
              history.push(routes.START_PAGE);
            }}
          >
            Se connecter
          </BaseButton>
        </div>
      </div>
    );
  } else if (authCtx.userRole !== "RESTAURANT") {
    return (
      <div className="account__container">
        <div className="account__basic-text">
          Compte {authCtx.userRole == "COURIER" ? "Livreur" : "Client"}
        </div>
        <div className="account__signout-container">
          <BaseButton
            expand
            type="submit"
            outline
            onClick={() => {
              authCtx.signOut();
            }}
          >
            Se déconnecter
          </BaseButton>
        </div>
      </div>
    );
  }
  return (
    <div className="account__container">
      <div className="account__basic-text">
        {restaurantDataCtx.restaurantInfos.name
          ? restaurantDataCtx.restaurantInfos.name
          : "Aucun nom de restaurant renseigné"}
      </div>
      <div className="account__basic-text">{authCtx.userEmail}</div>
      <div className="account__subscription-status-container">
        <div
          className={
            authCtx.subscriptionPlan == "NONE"
              ? "account__subscription-status__no-subscription"
              : authCtx.subscriptionPlan == "BASIC"
              ? "account__subscription-status__basic-subscription"
              : authCtx.subscriptionPlan == "PREMIUM"
              ? "account__subscription-status__premium-subscription"
              : ""
          }
        >
          {authCtx.subscriptionPlan == "NONE"
            ? ""
            : authCtx.subscriptionPlan == "BASIC"
            ? "Abonnement : Basic"
            : authCtx.subscriptionPlan == "PREMIUM"
            ? "Abonnement : Premium"
            : authCtx.subscriptionPlan == "PREMIUM_WITH_MATERIAL"
            ? "Abonnement : Pro"
            : null}
        </div>
      </div>
      {authCtx.subscriptionPlan == "NONE" && !authCtx.userStripeAccountId ? (
        <div className="account__subscription-button-container">
          <BaseButton
            expand
            type="submit"
            onClick={handleActivateStripeAccount}
            isLoading={isLoading}
          >
            Créer mon compte stripe
          </BaseButton>
        </div>
      ) : authCtx.subscriptionPlan ==
        "BASIC" ? null : authCtx.userStripeAccountId ? (
        <div className="account__stripe-container">
          <div className="account__subscription-stripe-status-container">
            <div className="account__basic-tex">Informations stripe :</div>
            {authCtx.userStripeInfoRequired ||
            !authCtx.userStripeAccountActivated ? (
              <div className="account__subscription-status__no-subscription">
                Non à jour
              </div>
            ) : (
              <div className="account__subscription-status__stripe-updated">
                A jour
              </div>
            )}
          </div>

          {!authCtx.userStripeAccountActivated ||
          authCtx.userStripeInfoRequired ? (
            <div className="account__subscription-button-container">
              <BaseButton
                expand
                type="submit"
                outline
                onClick={() => {
                  window.open(authCtx.userStripeLinkUrl, "_blank");
                }}
                showMouse
              >
                <div className="stripe-button">
                  Compléter mon profil
                  <IonImg
                    src={stripeLogo}
                    className="account__subscription-stripe-logo"
                  />
                </div>
              </BaseButton>
            </div>
          ) : null}
          {authCtx.userStripeAccountDashboardLink ? (
            <div className="account__subscription-button-container">
              <BaseButton
                expand
                type="submit"
                outline
                onClick={() => {
                  window.open(authCtx.userStripeAccountDashboardLink, "_blank");
                }}
              >
                <div className="stripe-button">
                  Dashboard
                  <IonImg
                    src={stripeLogo}
                    className="account__subscription-stripe-logo"
                  />
                </div>
              </BaseButton>
            </div>
          ) : null}
        </div>
      ) : null}
      {isUserSubscribed && authCtx.userStripeSubscriptionActive ? (
        <div className="account__subscription-button-container">
          <BaseButton
            expand
            type="submit"
            outline
            onClick={() => {
              present({
                header: "Annuler l'abonnement",
                message:
                  "êtes-vous sûr de vouloir vous désabonner et de perdre vos avantages ?",
                buttons: [
                  {
                    text: "Confirmer",
                    handler: async (d) => {
                      const idToken =
                        await $firebaseAuth.currentUser?.getIdToken();
                      if (idToken) {
                        const apiEndpoint =
                          buildEnv.BUILD_ENV == "DEV"
                            ? apiEndpoints.cancelSubscription.dev
                            : apiEndpoints.cancelSubscription.prod;
                        const headers = {
                          Authorization: `Bearer ${idToken}`,
                        };
                        axios
                          .post(
                            apiEndpoint,
                            {},
                            {
                              headers: headers,
                            }
                          )
                          .then((response) => {
                            // homeCtx.currentHomePageChangeHandler(
                            //   "SUBSCRIPTION_CANCELED"
                            // );
                            history.push(routes.SUBSCRIPTION_CANCELED);
                            dismiss();
                          })
                          .catch((err) => {
                            console.log(err);
                            dismiss();
                          });
                      }
                    },
                  },
                  {
                    text: "Annuler",
                    handler: (d) => {
                      dismiss();
                    },
                  },
                ],
                onDidDismiss: (e) => {
                  dismiss();
                },
              });
            }}
          >
            Se désabonner
          </BaseButton>
        </div>
      ) : null}

      {authCtx.userStripeAccountActivated &&
      !authCtx.userStripeInfoRequired &&
      authCtx.userStripeAccountId &&
      authCtx.userStripeAccountDashboardLink ? (
        <div
          style={{
            marginBottom: "2.5rem",
            marginTop: "1rem",
          }}
        >
          <div
            style={{
              color: "#4CAF50",
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            Vous êtes prêt à utiliser notre application ainsi que toutes les
            fonctionnalités !
          </div>
          <div style={{ paddingTop: "1rem" }}>
            <a href={constants.APP_URL}>Accéder à l'application</a>
          </div>
        </div>
      ) : null}
      <div className="account__signout-container">
        <BaseButton
          expand
          type="submit"
          outline
          onClick={() => {
            authCtx.signOut();
          }}
        >
          Se déconnecter
        </BaseButton>
      </div>
    </div>
  );
};

export default Account;
