import React, { useContext, useState } from "react";
import AuthContext from "../../contexts/auth.ctx";
import HomeContext from "../../contexts/home.ctx";
import { constants } from "../../utils/constants";
import "./Fees.css";

const Fees: React.FC = (props) => {
  return (
    <div className="fees__main-container">
      <div className="fees__text-header">Frais de transaction STRIPE:</div>
      <div className="fees__text">
        Des frais de transaction bancaire s'appliquent sur les commandes que vos
        clients effectuent par carte bancaire sur votre site en ligne. (compte
        premium), ces frais sont de:
      </div>

      <div className="fees__text">
        {" "}
        1,65% + 0.25{constants.CURRENCY} par commande sur votre site web.
      </div>

      <div className="fees__text">
        {" "}
        Virements sur votre compte: Les virements s'effectuent chaque semaine
        directement sur votre compte en banque
      </div>
      <div className="fees__text">
        Notre partenaire STRIPE leader mondial des paiements en ligne se charge
        de la sécurité de toutes vos transactions.
      </div>
      <div className="fees__text">
        Vous aurez accès à un tableau de bord STRIPE simplifié, ou vous pourrez
        choisir la fréquence de vos virements, et retrouver toutes vos factures
        de frais.
      </div>
    </div>
  );
};

export default Fees;
