import React from "react";
import { IonContent, IonImg, IonPage } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router";
import NavBar from "../../components/NavBar/NavBar";
import SubscribeToPlanComponent from "../../components/Subscribe/SubscribeToPlanComponent";
import SubscriptionSuccess from "../../components/Success/SubscriptionSuccess";
import Account from "../../components/Account/Account";
import ChooseSubscriptionPlan from "../../components/ChooseSubscripionPlan/ChooseSubscriptionPlan";
import HomeContext from "../../contexts/home.ctx";
import Footer from "../../components/Footer/Footer";
import AuthContext, {
  AuthStates,
  SubscriptionPlan,
} from "../../contexts/auth.ctx";
import "./RefrechStripeAccountLink.css";
import routes from "../../routes";
import BaseButton from "../../components/@Base/BaseButton/BaseButton";
import AppLoading from "../../components/@Shared/AppLoading/AppLoading";
const RefrechStripeAccountLink: React.FC = (props) => {
  const homeCtx = useContext(HomeContext);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (
      authCtx.authState == AuthStates.AUTH &&
      (!authCtx.userStripeAccountActivated || authCtx.userStripeInfoRequired)
    ) {
      authCtx.getNewStripeAccountLink();
    }
  }, [authCtx.authState]);

  if (authCtx.authState == AuthStates.NOT_AUTH) {
    return <Redirect to={routes.START_PAGE} />;
  } else if (authCtx.authState == AuthStates.NOT_INIT) {
    return <AppLoading />;
  }
  return (
    <div className="refresh-stripe-link-main-container">
      <div className="refresh-stripe-link-text-container">Le lien a expiré</div>
      {authCtx.isLoading ? (
        <div className="refresh-stripe-link-text-small">
          Veuillez patientez pendant que nous tentons de régénérer le lien...
        </div>
      ) : (
        <div className="refresh-stripe-link-text-small">
          Le lien est prêt ! Veuillez cliquez ci-dessous pour y accéder
        </div>
      )}
      <BaseButton
        expand
        type="submit"
        outline
        isLoading={authCtx.isLoading}
        onClick={() => {
          if (authCtx.userStripeLinkUrl) {
            window.open(authCtx.userStripeLinkUrl, "_blank");
          }
        }}
      >
        Compléter mes informations Stripe
      </BaseButton>
    </div>
  );
};

export default RefrechStripeAccountLink;
