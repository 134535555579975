export default {
  START_PAGE: "/auth/start",
  SIGN_UP_PAGE: "/auth/signup",
  SIGN_OUT: "/auth/signout",
  SIGN_IN_PAGE: "/auth/signin",
  RESET_PASSWORD_PAGE: "/auth/reset-password",
  HOME_PAGE: "/home",
  ROOT: "/",
  FAQ: "/home/faq",
  FEES: "/home/fees",
  CONTACT: "/home/contact",
  VIDEO: "/home/video",
  REFRESH_STRIPE_LINK: "/home/refresh_stripe_link",
  SUBSCRIBE_BASIC: "/home/subscribe_basic",
  SUBSCRIBE_PREMIUM: "/home/subscribe_premium",
  SUBSCRIBE_PREMIUM_WITH_MATERIAL: "/home/subscribe_premium_with_material",
  SUBSCRIPTION_SUCCESS: "/home/subscription_success",
  ACCOUNT_PAGE: "/home/account_page",
  CHOOSE_SUBSCRIPTION_PLAN: "/home/choose_subscription_plan",
  STRIPE_ACCOUNT_SUCCESS: "/home/stripe_account_success",
  DOWNLOAD: "/home/download",
  SUBSCRIPTION_CANCELED: "/home/subscription_canceled",
  ADMIN: "/home/admin",
  EXAMPLE: "/home/example",
  // STRIPE_REFRESH_ACCOUNT_LINK: "/refreshAccountLink",
  // STRIPE_ACCOUNT_SUCCESS: "/stripeAccountSuccess"
  // ORDERS_PAGE: "/home/orders",
  // COURIERS_PAGE: "/home/couriers",
  // PRODUCTS_PAGE: "/home/products",
  // ACCOUNT_PAGE: "/home/account",
  // DELIVERIES_PAGE: "/home/delivery",
  // PRODUCT_EDITION_PAGE: "/ctxl/add-product",
  // NEW_ORDER_PAGE: "/ctxl/new-order",
  // NEW_DELIVERY_PAGE: "/ctxl/new-delivery",
  // SETTINGS_PAGE: "/home/settings",
};
