import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import {
  FunctionalSuccessAlert,
  SignInResponse,
  SuccessAlert,
  userData,
} from "../types";
import {
  $firebaseAuth,
  $firebaseFunctions,
  $firestore,
} from "./firebase-service";
import errors from "../messages/error";
import success from "../messages/success";
import buildEnv from "../build.env.json";
import axios from "axios";
import { constants } from "../utils/constants";

const apiEndpoints = {
  getUserData: {
    dev: `${constants.API_URL}/customerApi/getUserData`,
    prod: `${constants.API_URL}/customerApi/getUserData`,
  },
  updateUserData: {
    dev: `${constants.API_URL}/customerApi/updateUserData`,
    prod: `${constants.API_URL}/customerApi/updateUserData`,
  },
  getNewStripeAccountLink: {
    dev: `${constants.API_URL}/sellerApi/getNewStripeAccountLinkDev`,
    prod: `${constants.API_URL}/sellerApi/getNewStripeAccountLink`,
  },
};

const authServices = {
  signUpWithEmailAndPassword(
    email: string,
    password: string,
    userRole: string,
    companyName?: string,
    phone?: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      const createNewUser = $firebaseFunctions.httpsCallable("createNewUser");
      // NOTE : in previous versions sign up was available for restaurant but not anymore
      createNewUser({ email, password, userRole: userRole, phone, companyName })
        .then((result) => {
          if (result.data.status == "ERROR") {
            if (result.data.message.fbCode == "auth/email-already-exists") {
              rej(errors.USER_ALREADY_EXISTS_ERROR_ALERT);
            } else {
              rej(errors.SIGNUP_ERROR_ALERT);
            }
          } else {
            return res(success.SIGNUP_SUCCESS_ALERT);
          }
        })
        .catch((e) => {
          console.log(e);
          return rej(errors.SIGNUP_ERROR_ALERT);
        });
    });
  },
  getAuthenticatedUserData(): Promise<any> {
    return new Promise(async (res, rej) => {
      const idToken = await $firebaseAuth.currentUser?.getIdToken();
      if (idToken) {
        const apiEndpoint =
          buildEnv.BUILD_ENV == "DEV"
            ? apiEndpoints.getUserData.dev
            : apiEndpoints.getUserData.prod;
        const headers = {
          Authorization: `Bearer ${idToken}`,
        };
        axios
          .get(apiEndpoint, {
            headers: headers,
          })
          .then((response) => {
            res(response.data.userData);
          })
          .catch((err) => {
            rej(err);
          });
      } else {
        rej("no user authenticated");
      }
    });
  },
  getNewStripeAccountLink(): Promise<any> {
    return new Promise(async (res, rej) => {
      const idToken = await $firebaseAuth.currentUser?.getIdToken();
      if (idToken) {
        console.log(idToken);
        const apiEndpoint =
          buildEnv.BUILD_ENV == "DEV"
            ? apiEndpoints.getNewStripeAccountLink.dev
            : apiEndpoints.getNewStripeAccountLink.prod;
        const headers = {
          Authorization: `Bearer ${idToken}`,
        };
        axios
          .post(
            apiEndpoint,
            {},
            {
              headers: headers,
            }
          )
          .then((response) => {
            res(response.data);
          })
          .catch((err) => {
            rej(err);
          });
      } else {
        rej("no user authenticated");
      }
    });
  },
  listenToAuthenticatedUser(userId: string, listenerCallback: Function) {
    const authenticatedUserListener = $firestore
      .collection("users")
      .doc(userId)
      .onSnapshot((querySnasphot) => {
        console.log("HERE !!!");
        let data = {
          ...querySnasphot.data(),
          id: querySnasphot.id,
        };
        listenerCallback(data);
      });
    return authenticatedUserListener;
  },
  signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<SignInResponse> {
    return new Promise((res, rej) => {
      $firebaseAuth
        .signInWithEmailAndPassword(email, password)
        .then(async (data) => {
          if ($firebaseAuth.currentUser) {
            const userToken =
              await $firebaseAuth.currentUser.getIdTokenResult();
            console.log("user is a restaurant role");
            res({
              token: userToken.token,
              claims: {
                role: userToken.claims.role,
                user_role: userToken.claims.user_role,
                restaurant_id: userToken.claims.restaurant_id,
                subscription_plan: userToken.claims.subscription_plan,
              },
            });
          } else {
            rej(errors.SIGNIN_ERROR_ALERT);
          }
        })
        .catch((e) => {
          if (e.code == "auth/user-not-found") {
            rej(errors.USER_NOT_FOUND_ERROR_ALERT);
          } else if (e.code == "auth/wrong-password") {
            rej(errors.WRONG_USER_PASSWORD_ERROR_ALERT);
          }
          rej(errors.SIGNIN_ERROR_ALERT);
        });
    });
  },
  updatePassword(
    userEmail: string,
    previousPassword: string,
    newPassword: string
  ): Promise<SuccessAlert> {
    return new Promise((res, rej) => {
      const credential = firebase.auth.EmailAuthProvider.credential(
        userEmail,
        previousPassword
      );
      $firebaseAuth.currentUser
        ?.reauthenticateWithCredential(credential)
        .then(() => {
          $firebaseAuth.currentUser
            ?.updatePassword(newPassword)
            .then(() => {
              res(success.PASSWORD_UPDATE_SUCCESS_ALERT);
            })
            .catch((err) => {
              rej(errors.PASSWORD_UPDATE_ERROR_ALERT);
            });
        })
        .catch((err) => {
          console.log(err);
          rej(errors.WRONG_CREDENTIALS_PASSWORD_UPDATE_ERROR_ALERT);
        });
    });
  },
  sendPasswordResetEmail(email: string): Promise<FunctionalSuccessAlert> {
    return new Promise((res, rej) => {
      $firebaseAuth
        .sendPasswordResetEmail(email)
        .then((data) => res(success.SEND_PASSWORD_RESET_EMAIL_SUCCESS_ALERT))
        .catch((e) => {
          rej(errors.RESET_PASSWORD_ERROR_ALERT);
        });
    });
  },
};
export default authServices;
