import React, { useContext } from "react";
import { useHistory } from "react-router";
import { IonIcon } from "@ionic/react";
import { call } from "ionicons/icons";

import "./CallButton.css";
import HomeContext from "../../contexts/home.ctx";

import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import AuthContext from "../../contexts/auth.ctx";

import BaseButton from "../@Base/BaseButton/BaseButton";

interface ContainerProps {}

const CallButton: React.FC = (props) => {
  const homeCtx = useContext(HomeContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  return (
    <a className="call-button__button-call" href="tel:0328493250">
      <BaseButton
        big
        expand
        showMouse
        type="button"
        // onClick={() => {
        //   history.push(routes.CONTACT);
        // }}
      >
        <div className="call-button__call-button-container">
          <IonIcon
            className="call-button__call-button-icon"
            icon={call}
            color={"light"}
          />
          <div className="call-button__call-text-container">
            <span className="call-button__call-text-small">Une question ?</span>
            <span className="call-button__call-text-big">Appelez-nous</span>
          </div>
        </div>
      </BaseButton>
    </a>
  );
};

export default CallButton;
