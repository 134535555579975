import React, { useEffect, useState, useContext, useRef } from "react";
import {
  PaymentElement,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./CheckoutForm.css";
import { useIonAlert } from "@ionic/react";
import BaseButton from "../@Base/BaseButton/BaseButton";
import buildEnv from "../../build.env.json";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import { $firebaseAuth } from "../../services/firebase-service";
import { useHistory } from "react-router-dom";
import AuthContext, { SubscriptionPlan } from "../../contexts/auth.ctx";
import axios from "axios";
import HomeContext from "../../contexts/home.ctx";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import authServices from "../../services/auth-service";
import routes from "../../routes";
import { constants } from "../../utils/constants";

const { gtag, install } = require("ga-gtag");
// OUR_TRACKING_ID

interface CheckoutFormProps {
  clientSecret?: string;
  subscriptionPlan: SubscriptionPlan;
}

const apiEndpoints = {
  paymentIntent: {
    dev: `${constants.API_URL}/sellerApi/createPaymentIntentSubscriptionDev`,
    prod: `${constants.API_URL}/sellerApi/createPaymentIntentSubscription`,
  },
};

const CheckoutForm: React.FC<CheckoutFormProps> = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const cardElementRef = useRef(null);
  const homeCtx = useContext(HomeContext);
  const authCtx = useContext(AuthContext);
  const subOrderCtx = useContext(SubscriptionOrderContext);
  let history = useHistory();
  const [present, dismiss] = useIonAlert();
  const [stripeAccountUrl, setStripeAccountUrl] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    gtag("event", "add_payment_info", {
      payment_type: "Credit Card",
      items: [
        {
          item_id: props.subscriptionPlan,
          item_name: props.subscriptionPlan,
          currency: "EUR",
          price: props.subscriptionPlan == "PREMIUM" ? 82.8 : 70.8,
          quantity: 1,
        },
      ],
    });
    const cardElement = elements.getElement("cardNumber");
    subOrderCtx.subscriptionChangeHandler(props.subscriptionPlan);
    subOrderCtx.subscriptionPriceHtChangeHandler(
      props.subscriptionPlan == "PREMIUM" ? 99 : 69
    );
    subOrderCtx.subscriptionPriceTtcChangeHandler(
      props.subscriptionPlan == "PREMIUM" ? 82.8 : 70.8
    );
    let paymentMethodId: string | undefined;
    if (cardElement) {
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
        })
        .then((result) => {
          paymentMethodId = result.paymentMethod?.id;
          const requestParams = {
            paymentMethodId: result.paymentMethod?.id,
            subscriptionType: props.subscriptionPlan,
          };
          const apiEndpoint =
            buildEnv.BUILD_ENV == "DEV"
              ? apiEndpoints.paymentIntent.dev
              : apiEndpoints.paymentIntent.prod;

          $firebaseAuth.currentUser
            ?.getIdToken()
            .then((idToken) => {
              const headers = {
                Authorization: `Bearer ${idToken}`,
              };
              axios
                .post(apiEndpoint, requestParams, {
                  headers: headers,
                })
                .then(async (response) => {
                  if (response.status == 500) {
                    console.log("Error");
                  }

                  setIsLoading(false);
                  console.log(response.data);
                  if (response.data?.url) {
                    if (response.data?.paymentStatus == "KO") {
                      console.log(response.data?.clientSecret, paymentMethodId);
                      try {
                        const confirmPaymentReturn =
                          await stripe.confirmCardPayment(
                            response.data?.clientSecret,
                            {
                              payment_method: paymentMethodId,
                              // setup_future_usage: "on_session",
                              // Make sure to change this to your payment completion page
                              return_url:
                                process.env.NODE_ENV === "development"
                                  ? `http://localhost:8100/${routes.SUBSCRIPTION_SUCCESS}`
                                  : buildEnv.BUILD_ENV === "DEV"
                                  ? `${constants.WEBSITE_URL}/${routes.SUBSCRIPTION_SUCCESS}` // Change on real dev mode
                                  : `${constants.WEBSITE_URL}/${routes.SUBSCRIPTION_SUCCESS}`,
                            },
                            {
                              handleActions: true,
                            }
                          );
                      } catch (err) {
                        console.log(err);

                        throw Error("Erreur lors de la validation 3D secure");
                      }
                    }
                    gtag("event", "purchase", {
                      value: props.subscriptionPlan == "PREMIUM" ? 82.8 : 70.8,
                      currency: "EUR",
                      items: [
                        {
                          item_id: props.subscriptionPlan,
                          item_name: props.subscriptionPlan,
                          currency: "EUR",
                          price:
                            props.subscriptionPlan == "PREMIUM" ? 82.8 : 70.8,
                          quantity: 1,
                        },
                      ],
                    });
                    subOrderCtx.stripeConnectedAccountUrlChangeHandler(
                      response.data.url
                    );

                    history.push(routes.SUBSCRIPTION_SUCCESS);
                    authCtx.getUserData();
                  } else {
                    history.push(routes.SUBSCRIPTION_SUCCESS);
                    authCtx.getUserData();
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  present({
                    header: "Une erreur est survenue",
                    message: "Veuillez réessayer",
                    buttons: [{ text: "Ok", handler: (d) => {} }],
                    onDidDismiss: (e) => {
                      dismiss();
                    },
                  });
                  console.log(err);
                });
            })
            .catch((err) => {
              setIsLoading(false);
              present({
                header: "Une erreur est survenue",
                message: "Veuillez réessayer",
                buttons: [{ text: "Ok", handler: (d) => {} }],
                onDidDismiss: (e) => {
                  dismiss();
                },
              });
              console.log(err);
            });
          // Handle result.error or result.paymentMethod
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          present({
            header: "Une erreur est survenue",
            message: "Veuillez réessayer",
            buttons: [{ text: "Ok", handler: (d) => {} }],
            onDidDismiss: (e) => {
              dismiss();
            },
          });
        });
    } else {
      setIsLoading(false);
      present({
        header: "Une erreur est survenue",
        message: "Veuillez réessayer",
        buttons: [{ text: "Ok", handler: (d) => {} }],
        onDidDismiss: (e) => {
          dismiss();
        },
      });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <>
        <div>
          <div className="payment-element-form-label">Numéro de carte :</div>
          <div className="payment-element-form-card">
            <CardNumberElement
              id="payment-element"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                  },
                },
                iconStyle: "solid",
                showIcon: true,
              }}
            />
          </div>
        </div>
        <div className="payment-element-form-container-cvc-expiry">
          <div className="payment-element-form-container">
            <div className="payment-element-form-label">Expiration :</div>
            <div className="payment-element-form-card">
              <CardExpiryElement
                id="payment-element"
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="payment-element-form-container">
            <div className="payment-element-form-label">CVC :</div>
            <div className="payment-element-form-card">
              <CardCvcElement
                id="payment-element"
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <BaseButton
          expand
          disabled={isLoading || !stripe || !elements}
          type="submit"
          isLoading={isLoading}
        >
          Confirmer le paiement
        </BaseButton>
        {message && (
          <div style={{ color: "red" }} id="payment-message">
            {message}
          </div>
        )}
      </>
    </form>
  );
};
export default CheckoutForm;
