import GoogleCloudService from "./google-cloud-service";
import ShopService from "./shop-service";
import AdminService from "./admin-service";

const mainService = new ShopService();
const GCS = new GoogleCloudService();
const adminService = new AdminService();
export { GCS };
export { adminService };

export default mainService;
