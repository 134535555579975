import { IonContent, IonPage } from "@ionic/react";
import BasePageHeader from "../../@Base/BasePageHeader/BasePageHeader";
import "./StartLayout.css";

export enum StartLayoutDispositions {
  "START",
  "SIGNIN",
  "SIGNUP",
  "PASSWORD_RESET",
}

interface StartLayoutProps {
  disposition: StartLayoutDispositions;
  title?: string;
  defaultBackPath?: string;
}

const getStartLayoutDisposition = (disposition: StartLayoutDispositions) => {
  const classes = ["start-layout__main__content"];
  switch (disposition) {
    case StartLayoutDispositions.START:
      classes.push("start-layout__main__content--start");
      break;
    case StartLayoutDispositions.SIGNUP:
      classes.push("start-layout__main__content--signup");
      break;
    case StartLayoutDispositions.SIGNIN:
      classes.push("start-layout__main__content--signin");
      break;
    case StartLayoutDispositions.PASSWORD_RESET:
      classes.push("start-layout__main__content--password-reset");
      break;
  }
  return classes.join(" ");
};

const getConfigFromDisposition = (disposition: StartLayoutDispositions) => {
  switch (disposition) {
    case StartLayoutDispositions.START:
      return { title: null, defaultBackPath: undefined };
    case StartLayoutDispositions.SIGNUP:
      return { title: "S'inscrire", defaultBackPath: undefined };
    case StartLayoutDispositions.SIGNIN:
      return { title: "Se connecter", defaultBackPath: undefined };
    case StartLayoutDispositions.PASSWORD_RESET:
      return { title: "Mot de passe oublié", defaultBackPath: undefined };
  }
};

const StartLayout: React.FC<StartLayoutProps> = (props) => {
  const configFromDisposition = getConfigFromDisposition(props.disposition);
  return (
    <IonPage className="start-layout" data-testid="start-layout">
      {configFromDisposition?.title && (
        <BasePageHeader
          title={configFromDisposition.title}
          defaultBackPath={configFromDisposition.defaultBackPath}
        />
      )}
      <IonContent data-testid="start-layout__ion-content">
        <main className="start-layout__main" data-testid="start-layout__main">
          <section className={getStartLayoutDisposition(props.disposition)}>
            {props.children}
          </section>
        </main>
      </IonContent>
    </IonPage>
  );
};

export default StartLayout;
