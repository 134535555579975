import SignUpForm from "../../../components/Start/SignUpForm/SignUpForm";
import { SignUpContextProvider } from "../../../contexts/sign-up.ctx";
import StartLayout, {
  StartLayoutDispositions,
} from "../../../components/Layouts/StartLayout/StartLayout";

const SignUpPage: React.FC = () => {
  return (
    <SignUpContextProvider>
      <StartLayout disposition={StartLayoutDispositions.SIGNUP}>
        <SignUpForm />
      </StartLayout>
    </SignUpContextProvider>
  );
};

export default SignUpPage;
