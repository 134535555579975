import config from "../configs/google-cloud-service.config";
import { Loader } from "@googlemaps/js-api-loader";

const gcServiceConfig = config();
const GOOGLE_MAPS_API_KEY = gcServiceConfig.googleMapsApiKey;
const GOOGLE_PLACES_API_KEY = gcServiceConfig.googlePlacesApiKey;

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places"],
});

export default class GoogleCloudService {
  async getPlacesSuggestions(
    input: string,
    deviceLatLng: { lat: number; lng: number }
  ) {
    return new Promise((res, rej) => {
      loader
        .load()
        .then((google) => {
          const autocomplete = new google.maps.places.AutocompleteService();
          var location = new google.maps.LatLng(deviceLatLng);
          autocomplete.getPlacePredictions(
            { input, location, radius: 25000 },
            (predictions, status) => {
              const predictionsList: google.maps.places.AutocompletePrediction[] =
                [];
              predictions?.forEach((prediction) => {
                predictionsList.push(prediction);
              });
              res(predictionsList);
            }
          );
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }
  async getPlaceGeocoding(
    placeId: string
  ): Promise<google.maps.GeocoderResponse> {
    return new Promise((res, rej) => {
      loader
        .load()
        .then((google) => {
          const geocoder = new google.maps.Geocoder();
          geocoder
            .geocode({ placeId: placeId })
            .then((results) => {
              res(results);
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }
}
