import buildEnv from "../build.env.json";
import axios from "axios";
import Product from "../models/Product";
import ProductCategory from "../models/ProductCategory";
import ProductsOptions from "../models/ProductsOptions";
import RestaurantInfos from "../models/RestaurantInfos";
import WorkingDays from "../models/WorkingDays";
import { $firebaseAuth } from "./firebase-service";
import { constants } from "../utils/constants";

const defaultRestaurantId =
  buildEnv.BUILD_ENV == "PROD"
    ? "9fce979a-02f0-4156-b33d-9f2c215bf8cf" //Happy
    : "f8edcd4e-e195-4285-96ae-b65e3ccb89d3";
const apiEndpoints = {
  restaurantData: {
    dev: `${constants.API_URL}/customerApi/restaurantData`,
    prod: `${constants.API_URL}/customerApi/restaurantData`,
  },
  subscribeWithCoupon: {
    dev: `${constants.API_URL}/sellerApi/subscribeWithCouponDev`,
    prod: `${constants.API_URL}/sellerApi/subscribeWithCoupon`,
  },
};
export type RestaurantData = {
  restaurantInfos: RestaurantInfos;
  products: Product[];
  productCategories: ProductCategory[];
  productsOptions: ProductsOptions[];
  workingDays: WorkingDays;
};
export default class ShopService {
  async getRestaurantData(restaurantId: string): Promise<RestaurantData> {
    return new Promise((res, rej) => {
      const apiEndpoint =
        buildEnv.BUILD_ENV == "DEV"
          ? apiEndpoints.restaurantData.dev
          : apiEndpoints.restaurantData.prod;

      axios
        .post(apiEndpoint, {
          restaurantId: restaurantId ? restaurantId : defaultRestaurantId,
        })
        .then((response: Record<any, any>) => {
          console.log(response.data);
          const restaurantInfos = new RestaurantInfos(
            response.data.restaurantInfos?.id,
            response.data.restaurantInfos?.name,
            response.data.restaurantInfos?.phone,
            response.data.restaurantInfos?.email,
            response.data.restaurantInfos?.logo_url,
            response.data.restaurantInfos?.logo_thumbnail_url,
            response.data.restaurantInfos?.address_first_line,
            response.data.restaurantInfos?.address_second_line,
            response.data.restaurantInfos?.address_zipcode,
            response.data.restaurantInfos?.address_city,
            response.data.restaurantInfos?.address_latitude,
            response.data.restaurantInfos?.address_longitude,
            response.data.restaurantInfos?.address_place_id,
            response.data.restaurantInfos?.current_delivery_time,
            response.data.restaurantInfos?.delivery_fees,
            response.data.restaurantInfos?.free_delivery_fees_trigger,
            response.data.restaurantInfos?.is_closed
          );
          const products: Product[] = [];
          response.data.products?.map((prod: Record<any, any>) => {
            products.push(
              new Product(
                prod.id,
                prod.name,
                prod.price,
                prod.category_id,
                prod.has_options,
                prod.picture_url,
                prod.thumbnail_url,
                prod.description,
                prod.products_options_ids
              )
            );
          });
          const categories: ProductCategory[] = [];
          response.data.productCategories?.map((cat: Record<any, any>) => {
            categories.push(
              new ProductCategory(cat.id, cat.name, cat.color, cat.position)
            );
          });
          const options: ProductsOptions[] = [];
          response.data.productsOptions?.map((opt: Record<any, any>) => {
            options.push(
              new ProductsOptions(
                opt.id,
                opt.name,
                opt.type,
                opt.option_items,
                opt.is_mandatory
              )
            );
          });

          const workingDays = response.data.restaurantInfos?.workingDays;
          res({
            restaurantInfos: restaurantInfos,
            products: products,
            productCategories: categories,
            productsOptions: options,
            workingDays: workingDays,
          });
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }
  async subscribeWithCoupon(
    subscriptionType: string,
    couponCode: string
  ): Promise<any> {
    return new Promise(async (res, rej) => {
      const apiEndpoint =
        buildEnv.BUILD_ENV == "DEV"
          ? apiEndpoints.subscribeWithCoupon.dev
          : apiEndpoints.subscribeWithCoupon.prod;
      const idToken = await $firebaseAuth.currentUser?.getIdToken();
      if (idToken) {
        const headers = {
          Authorization: `Bearer ${idToken}`,
        };
        axios
          .post(
            apiEndpoint,
            {
              subscriptionType: subscriptionType,
              couponCode: couponCode,
            },
            {
              headers: headers,
            }
          )
          .then((response: Record<any, any>) => {
            res(response);
          })
          .catch((e) => {
            console.log(e);
            rej(e);
          });
      } else {
        rej({ error: "Not auth" });
      }
    });
  }
}
