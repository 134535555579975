import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { IonIcon } from "@ionic/react";
import {
  close,
  menu,
  person,
  videocam,
  help,
  chatbubble,
  logOut,
  cloudDownload,
  informationCircle,
  home,
  navigate,
} from "ionicons/icons";
import "./NavBar.css";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import HomeContext from "../../contexts/home.ctx";
import logo from "../../assets/logo/logo.png";
import BaseButton from "../@Base/BaseButton/BaseButton";
import buildEnv from "../../build.env.json";
import routes from "../../routes";
import { constants } from "../../utils/constants";
interface ContainerProps {
  setShowPage: Function;
}

interface MenuProps {
  authState: AuthStates;
  userRole: string;
  onClick: Function;
  navigate: Function;
  setToggleMenu: Function;
}

const MenuSubscriber: React.FC<MenuProps> = (props) => {
  const authCtx = useContext(AuthContext);
  return (
    <>
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={home} />
        </div>
        <p>
          <a
            onClick={() => {
              props.setToggleMenu(false);
              props.navigate(routes.HOME_PAGE);
            }}
          >
            Accueil
          </a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={person} />
          {!authCtx.userStripeAccountActivated ||
          !authCtx.userStripeAccountId ||
          authCtx.userStripeInfoRequired ? (
            <div
              style={{
                position: "absolute",
                top: -4,
                right: -4,
                backgroundColor: "red",
                width: 10,
                height: 10,
                borderRadius: 10,
              }}
            ></div>
          ) : null}
        </div>
        <p>
          <a onClick={() => props.onClick()}>Mon Compte</a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={logOut} />
        </div>
        <p>
          <a href={constants.APP_URL}>Accéder à l'application</a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={cloudDownload} />
        </div>
        <p>
          <a
            onClick={() => {
              props.setToggleMenu(false);
              props.navigate(routes.DOWNLOAD);
            }}
          >
            Télécharger
          </a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={videocam} />
        </div>
        <p>
          <a
            onClick={() => {
              props.setToggleMenu(false);
              props.navigate(routes.VIDEO);
            }}
          >
            Premiers pas en vidéo
          </a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={help} />
        </div>
        <p>
          <a
            onClick={() => {
              props.setToggleMenu(false);
              props.navigate(routes.FAQ);
            }}
          >
            Questions fréquentes
          </a>
        </p>
      </div>
    </>
  );
};

const MobileMenuInfo: React.FC<MenuProps> = (props) => {
  return (
    <div className="header__navbar-menu_container-links-menu-info">
      <div className="header__navbar-links_container_container-links">
        <p>
          <a>Votre propre site de commande et réservation</a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <p>
          <a>Un gain de temps record en livraison</a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <p>
          <a>Gestion des commandes en interne optimisés</a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <p>
          <a>Une application francaise à votre écoute</a>
        </p>
      </div>
      <div className="header__navbar-links_container_subscribe-container">
        <div className="header__navbar-links_container_subscribe-button-subscribe">
          <BaseButton
            expand
            outline
            type="submit"
            onClick={() => {
              props.onClick();
            }}
            margins={"1.5px 1.5px 1.5px 1.5px"}
          >
            S'inscrire
          </BaseButton>
        </div>
        {/* <div className="header__navbar-links_container_subscribe-container-triangle"></div> */}
      </div>
    </div>
  );
};

const Menu: React.FC<MenuProps> = (props) => {
  const authCtx = useContext(AuthContext);
  return (
    <>
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={home} />
        </div>
        <p>
          <a
            onClick={() => {
              props.setToggleMenu(false);
              props.navigate(routes.HOME_PAGE);
            }}
          >
            Accueil
          </a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={person} />
          {!authCtx.userStripeAccountActivated ||
          !authCtx.userStripeAccountId ||
          authCtx.userStripeInfoRequired ? (
            <div
              style={{
                position: "absolute",
                top: -4,
                right: -4,
                backgroundColor: "red",
                width: 10,
                height: 10,
                borderRadius: 10,
              }}
            ></div>
          ) : null}
        </div>
        <p>
          <a onClick={() => props.onClick()}>
            {props.authState == AuthStates.AUTH
              ? "Mon compte"
              : "Inscription / connexion"}
          </a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={informationCircle} />
        </div>
        <p>
          <a
            onClick={() => {
              props.setToggleMenu(false);
              props.navigate(routes.CHOOSE_SUBSCRIPTION_PLAN);
            }}
          >
            Fonctionnalités
          </a>
        </p>
      </div>

      {props.authState == AuthStates.AUTH && props.userRole == "COURIER" ? (
        <>
          <div className="header__navbar-links_container_container-links">
            <div className="header__navbar-links_container_container-links-logo">
              <IonIcon icon={logOut} />
            </div>
            <p>
              <a href={constants.APP_URL}>Accéder à l'application</a>
            </p>
          </div>
          <div className="header__navbar-links_container_container-links">
            <div className="header__navbar-links_container_container-links-logo">
              <IonIcon icon={cloudDownload} />
            </div>
            <p>
              <a
                onClick={() => {
                  props.setToggleMenu(false);
                  props.navigate(routes.DOWNLOAD);
                }}
              >
                Télécharger
              </a>
            </p>
          </div>
        </>
      ) : null}
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={help} />
        </div>
        <p>
          <a
            onClick={() => {
              props.setToggleMenu(false);
              props.navigate(routes.FAQ);
            }}
          >
            Questions fréquentes
          </a>
        </p>
      </div>
      <div className="header__navbar-links_container_container-links">
        <div className="header__navbar-links_container_container-links-logo">
          <IonIcon icon={chatbubble} />
        </div>
        <p>
          <a
            onClick={() => {
              props.setToggleMenu(false);
              props.navigate(routes.CONTACT);
            }}
          >
            Contactez-nous
          </a>
        </p>
      </div>
    </>
  );
};

const NavBar: React.FC<ContainerProps> = (props) => {
  const authCtx = useContext(AuthContext);
  const homeCtx = useContext(HomeContext);
  const [toggleMenu, setToggleMenu] = useState(false);
  const history = useHistory();

  const handleSubscribeClick = () => {
    setToggleMenu(false);
    history.push(routes.CHOOSE_SUBSCRIPTION_PLAN);
    // homeCtx.currentHomePageChangeHandler("CHOOSE_SUBSCRIPTION_PLAN");
  };
  useEffect(() => {
    props.setShowPage(!toggleMenu);
  }, [toggleMenu]);
  const handlePersonClick = () => {
    if (authCtx.authState == AuthStates.AUTH) {
      setToggleMenu(false);
      history.push(routes.ACCOUNT_PAGE);
      // homeCtx.currentHomePageChangeHandler("ACCOUNT_PAGE");
    } else {
      history.push(routes.START_PAGE);
      setToggleMenu(false);
    }
  };
  return (
    <>
      <div className="header__navbar">
        <div className="header__navbar-links">
          <div className="header__navbar-links_container">
            {authCtx.authState == AuthStates.AUTH ? (
              <MenuSubscriber
                onClick={() => handlePersonClick()}
                authState={authCtx.authState}
                navigate={history.push}
                setToggleMenu={setToggleMenu}
                userRole={authCtx.userRole}
              />
            ) : (
              <Menu
                onClick={() => handlePersonClick()}
                authState={authCtx.authState}
                navigate={history.push}
                setToggleMenu={setToggleMenu}
                userRole={authCtx.userRole}
              />
            )}
          </div>
        </div>
        {/* <div className="header__navbar-links_container">
          <div className="header__navbar-links_container_container-links">
            <div className="header__navbar-links_container_container-links-logo">
              <IonIcon icon={chatbubble} />
            </div>
            <p>
              <a href="#home">Contactez-nous</a>
            </p>
          </div>
        </div> */}
        <div className="header__navbar-menu">
          <div
            className="header__navbar-menu-title-container"
            onClick={() => {
              setToggleMenu(false);
              history.push(routes.HOME_PAGE);
              // homeCtx.currentHomePageChangeHandler("HOME");
            }}
          >
            <div className="header__navbar-menu-title-left">Izi</div>
            <div className="header__navbar-menu-title-middle">Up</div>

            <div className="header__navbar-menu-title-right">.fr</div>
          </div>
          {toggleMenu ? (
            <IonIcon
              color="#fff"
              icon={close}
              onClick={() => setToggleMenu(false)}
              className="header__navbar-menu__icon"
            />
          ) : (
            <IonIcon
              color="#fff"
              icon={menu}
              onClick={() => setToggleMenu(true)}
              className="header__navbar-menu__icon"
            />
          )}
        </div>
      </div>
      <div className="header__navbar-menu_container-overlay">
        {toggleMenu && (
          <div className="header__navbar-menu_container scale-up-center">
            <div className="header__navbar-menu_container-links">
              {authCtx.authState == AuthStates.AUTH ? (
                <MenuSubscriber
                  onClick={() => handlePersonClick()}
                  authState={authCtx.authState}
                  navigate={history.push}
                  setToggleMenu={setToggleMenu}
                  userRole={authCtx.userRole}
                />
              ) : (
                <Menu
                  onClick={() => handlePersonClick()}
                  authState={authCtx.authState}
                  navigate={history.push}
                  setToggleMenu={setToggleMenu}
                  userRole={authCtx.userRole}
                />
              )}

              {authCtx.subscriptionPlan !== "NONE" ? null : (
                <MobileMenuInfo
                  onClick={() => handleSubscribeClick()}
                  authState={authCtx.authState}
                  navigate={history.push}
                  setToggleMenu={setToggleMenu}
                  userRole={authCtx.userRole}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NavBar;
