import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { IonIcon, IonImg } from "@ionic/react";
import {
  close,
  menu,
  person,
  videocam,
  help,
  chatbubble,
} from "ionicons/icons";
import "./HomeHeader.css";
import AuthContext, { AuthStates } from "../../../contexts/auth.ctx";
import homme1 from "../../../assets/images/homme1.png";
import homme2 from "../../../assets/images/homme2.png";
import femme1 from "../../../assets/images/femme1.png";
import tickerPrinter from "../../../assets/images/ticket_printer.png";
import tablettePrinterMerged from "../../../assets/images/tablette-printer-merged.png";
import tablette1 from "../../../assets/images/tablette1.png";
interface ContainerProps {}

const ratioThreshold = 0.1;
const observerOptions= {
  root: null,
  rootMargin: "0px",
  threshold:ratioThreshold
}


const HomeHeader: React.FC<ContainerProps> = (props) => {
  const authCtx = useContext(AuthContext);

  const callbackFunction = (entries : any, observer: any)=> {

    entries.forEach((entry :any)=> {
      if(entry.intersectionRatio > ratioThreshold) {
        console.log("visible");
        observer.unobserve(entry.target);
        entry.target.classList.add('home-header__reveal-visible');
      }

     
    });
  }
  useEffect(()=> {
    const observer = new IntersectionObserver(callbackFunction, observerOptions);
    const docSelection = document.querySelector('.home-header__reveal'); 
    if(docSelection) {
      observer.observe(docSelection);
    }
    
  }, [])
  return (

      <div className="home-header__main-container">
        <div className="home-header__img-container">
          <IonImg src={homme1} className="home-header__img" />
          <IonImg src={femme1} className="home-header__img" />
          <div className="home-header__text-container">
            <div className="home-header__text-simple">izi</div>
            <div className="home-header__text-bold">UP.fr</div>
          </div>
          <IonImg src={homme2} className="home-header__img" />
        </div>
        <div className="home-header__img-container__mobile">
          <IonImg src={homme1} className="home-header__img-mobile" />
          <IonImg
            src={tablettePrinterMerged}
            className="home-header__img-printer"
          />
          {/* <IonImg src={tablette1} className="home-header__img-tablette" /> */}
          {/* <IonImg src={homme2} className="home-header__img" /> */}
        </div>
        <div className="home-header__features-container">
          <div className="home-header__features-text">Prise de commandes</div>
          <div className="home-header__features-text">Livraison</div>
          <div className="home-header__features-text">Click and collect</div>
          <div className="home-header__features-text">Commande en ligne</div>
          <div className="home-header__features-text">Sans commission</div>
        </div>
      </div>

  );
};

export default HomeHeader;
