import "./BasePageHeader.css";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";

interface ContainerProps {
  title?: string;
  defaultBackPath?: string;
}

const BasePageHeader: React.FC<ContainerProps> = (props) => {
  return (
    <IonHeader data-testid="base-page-header">
      <IonToolbar
        className={!isPlatform("ios") ? "material-page-toolbar" : ""}
        data-testid="base-page-header__ion-toolbar"
      >
        <IonButtons slot="start" data-testid="base-page-header__ion-buttons">
          <IonBackButton
            data-testid="base-page-header__ion-back-button"
            defaultHref={props.defaultBackPath}
          />
        </IonButtons>
        <IonTitle data-testid="base-page-header__ion-title">
          {props.title}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default BasePageHeader;
