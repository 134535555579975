import React, { useContext, useState } from "react";
import { IonIcon, IonImg } from "@ionic/react";
import {
  close,
  menu,
  person,
  videocam,
  help,
  chatbubble,
  lockClosed,
  helpCircle,
} from "ionicons/icons";
import stripeLogo from "../../assets/logo/stripe_logo.png";
import AuthContext, {
  AuthStates,
  SubscriptionPlan,
} from "../../contexts/auth.ctx";
import HomeContext from "../../contexts/home.ctx";
import CheckoutForm from "../Checkout/CheckoutForm";
import "./FAQ.css";
import StripePayment from "../../assets/images/logo-stripe.png";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import buildEnv from "../../build.env.json";
import Collapsible from "../Collapsible/Collapsible";
import FAQdata from "./FAQ-data";
import CallButton from "../CallButton/CallButton";

const FAQ: React.FC = (props) => {
  const authCtx = useContext(AuthContext);
  const homeCtx = useContext(HomeContext);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="faq__main-container">
      {FAQdata.map((data) => {
        return (
          <Collapsible label={data.question}>
            <div className="faq__content">{data.answer}</div>
          </Collapsible>
        );
      })}
      <div className="faq__footer-container">
        <div className="faq__footer-text">
          Vous ne trouvez pas la réponse à votre question?
        </div>
        <CallButton />
      </div>
    </div>
  );
};

export default FAQ;
