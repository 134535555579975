export type OptionType = "ONE" | "MULTI";
export type OptionItem = {
  id: string;
  name: string;
  price: number;
};

export default class {
  public id;
  public name;
  public type;
  public optionItems;
  public isMandatory;
  constructor(
    id: string,
    name: string,
    type: OptionType,
    optionItems: OptionItem[],
    isMandatory?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.optionItems = optionItems;
    this.isMandatory = isMandatory;
  }
}
