const data = [
  {
    question: "Sur quel type de matériel puis-je utiliser votre application ?",
    answer:
      "Vous pouvez utiliser iziUP sur tous vos appareils. Le format le plus adapté est la tablette pour la prise et la gestion de commande, et le smartphone pour les livreurs.",
  },
  {
    question: "Installation et parametrage.",
    answer:
      `Une fois abonné, il suffit de cliquer sur l'onglet "accéder à l'application" ou téléchargez l'application via l'onglet "téléchargement"
      Le paramétrage est très simple, en cas de besoin, vous bénéficierez d'un accompagnement personnalisé sur la ligne tgéléphonique prévue à cet effet.`,
  },
];

export default data;
