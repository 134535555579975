import { useContext, useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { Redirect } from "react-router";
import AppLoading from "../../components/@Shared/AppLoading/AppLoading";
import StartChoices from "../../components/Start/StartChoices/StartChoices";
import StartLayout, {
  StartLayoutDispositions,
} from "../../components/Layouts/StartLayout/StartLayout";
import "./StartPage.css";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";

import routes from "../../routes";
const StartPage: React.FC = () => {
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    console.log("HERRR");
  });
  return (
    <StartLayout disposition={StartLayoutDispositions.START}>
      <div className="start-layout--start" data-testid="start-layout">
        {authCtx.authState == AuthStates.NOT_INIT ? (
          <AppLoading />
        ) : authCtx.authState == AuthStates.NOT_AUTH ? (
          <StartChoices />
        ) : (
          <Redirect exact to={routes.HOME_PAGE} />
        )}
      </div>
    </StartLayout>
  );
};

export default StartPage;
