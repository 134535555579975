import { useContext } from "react";
import { Redirect, useHistory } from "react-router";
import AppLoading from "../../../components/@Shared/AppLoading/AppLoading";
import StartChoices from "../../../components/Start/StartChoices/StartChoices";
import StartLayout, {
  StartLayoutDispositions,
} from "../../../components/Layouts/StartLayout/StartLayout";
import AuthContext, { AuthStates } from "../../../contexts/auth.ctx";

import routes from "../../../routes";
const SignOutPage: React.FC = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  authCtx.signOut();
  history.push(routes.HOME_PAGE);
  return (
        <Redirect exact to={routes.HOME_PAGE} />
 
  );
};

export default SignOutPage;
