import React, { useEffect, useState } from "react";
import API from "../services";
import CustomerInfos from "../models/CustomerInfos";
import Product from "../models/Product";
import { Storage } from "@ionic/storage";
import { useHistory } from 'react-router-dom'
import buildEnv from "../build.env.json";



const HomeContext = React.createContext({
  isLoading: false,
  currentHomePage: "HOME",
  lastHomePage: "HOME",
  currentHomePageChangeHandler: (homePage: string) => {},
  lastHomePageChangeHandler: (homePage: string) => {},
  resetContext: ()=> {},
});

export const HomeContextProvider: React.FC = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [currentHomePage, setCurrentHomePage] = useState("HOME");
  const [lastHomePage, setLastHomePage] = useState("HOME");

  const history = useHistory();

  const currentHomePageChangeHandler = (homePage: string) => {
    setCurrentHomePage(homePage);
  };

  const lastHomePageChangeHandler = (homePage: string) => {
    setLastHomePage(homePage);
  };

  const resetContext = () => {
    setCurrentHomePage("HOME");
    setLastHomePage("HOME");
    setIsLoading(false);
  };

  useEffect(() => {
    if(history){
      return history.listen((location : any) => {
        console.log("HERY");
        if (history.action === 'POP') {
          setCurrentHomePage(lastHomePage);
        }
      });
    }
    
  }, [currentHomePage]);

  return (
    <HomeContext.Provider
      value={{
        isLoading,
        currentHomePage,
        lastHomePage,
        currentHomePageChangeHandler,
        lastHomePageChangeHandler,
        resetContext,
      }}
    >
      {props.children}
    </HomeContext.Provider>
  );
};

export default HomeContext;
