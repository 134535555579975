export default class {
  public id;
  public name;
  public phone;
  public email;
  public logoUrl;
  public logoThumbnailUrl;
  public addressFirstLine;
  public addressSecondLine;
  public addressZipcode;
  public addressCity;
  public addressLatitude;
  public addressLongitude;
  public addressPlaceId;
  public currentDeliveryTime;
  public deliveryFees;
  public freeDeliveryFeesTrigger;
  public isClosed;

  constructor(
    id: string,
    name: string,
    phone: string,
    email: string,
    logoUrl: string,
    logoThumbnailUrl: string,
    addressFirstLine: string,
    addressSecondLine: string,
    addressZipcode: string,
    addressCity: string,
    addressLatitude: number,
    addressLongitude: number,
    addressPlaceId: string,
    currentDeliveryTime: number,
    deliveryFees: number,
    freeDeliveryFeesTrigger : number,
    isClosed: boolean
  ) {
    this.id = id;
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.logoUrl = logoUrl;
    this.logoThumbnailUrl = logoThumbnailUrl;
    this.addressFirstLine = addressFirstLine;
    this.addressSecondLine = addressSecondLine;
    this.addressZipcode = addressZipcode;
    this.addressCity = addressCity;
    this.addressLatitude = addressLatitude;
    this.addressLongitude = addressLongitude;
    this.addressPlaceId = addressPlaceId;
    this.currentDeliveryTime = currentDeliveryTime;
    this.deliveryFees = deliveryFees;
    this.freeDeliveryFeesTrigger = freeDeliveryFeesTrigger;
    this.isClosed = isClosed;
  }
}
