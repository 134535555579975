import Order from "../../../models/Order";
import BaseModalHeader from "../../@Base/BaseModalHeader/BaseModalHeader";
import { useState } from "react";
import { useIonAlert } from "@ionic/react";
import { useHistory } from "react-router-dom";
import BaseTextInput from "../../@Base/BaseTextInput/BaseTextInput";
import "./CouponModal.css";
import BaseButton from "../../@Base/BaseButton/BaseButton";
import routes from "../../../routes";
import API from "../../../services";

interface ContainerProps {
  subscriptionType: string;
  subOrderCtx: any;
  authCtx: any;
  onDismiss: Function;
  history: any;
  // onConfirmAndDismiss: Function;
}

const CouponModal: React.FC<ContainerProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [code, setCode] = useState("");
  const [present, dismiss] = useIonAlert();

  const onConfirmHandler = () => {
    setIsLoading(true);
    API.subscribeWithCoupon(props.subscriptionType, code)
      .then((response) => {
        setIsLoading(false);
        props.onDismiss();
        console.log(response.data);
        if (response.data.url) {
          props.subOrderCtx.stripeConnectedAccountUrlChangeHandler(
            response.data.url
          );

          props.history.push(routes.SUBSCRIPTION_SUCCESS);
          props.authCtx.getUserData();
        } else {
          props.history.push(routes.SUBSCRIPTION_SUCCESS);
          props.authCtx.getUserData();
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        present({
          header: "Erreur",
          message: err.response
            ? err.response.data
              ? err.response.data.error
                ? "Erreur : " + err.response.data.error
                : "Une erreur est survenue."
              : "Une erreur est survenue."
            : "Une erreur est survenue.",
          buttons: [{ text: "Ok", handler: (d) => {} }],
          onDidDismiss: (e) => {
            dismiss();
          },
        });
      });
  };

  return (
    <div className="coupon-modal__container">
      <BaseModalHeader onDismiss={props.onDismiss}>
        Vérification code promo
      </BaseModalHeader>
      <div className="coupon-modal__main-container">
        <div>
          <div className="coupon-modal__main-text">
            Entrez votre code promo ci-dessous :
          </div>
          <BaseTextInput
            label="Code"
            controller={{
              value: code,
              onChange: (event: any) => {
                setCode(event.target.value);
              },
            }}
          />
        </div>
        <div className="coupon-modal__button-container">
          <div className="coupon-modal__button">
            <BaseButton
              expand
              outline
              disabled={isLoading}
              onClick={() => {
                props.onDismiss();
              }}
            >
              Annuler
            </BaseButton>
          </div>
          <div className="coupon-modal__button">
            <BaseButton
              expand
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => {
                onConfirmHandler();
              }}
            >
              Confirmer
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponModal;
