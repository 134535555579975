import buildEnv from "../build.env.json";
import axios from "axios";
import { $firebaseAuth, $firestore } from "./firebase-service";
import { constants } from "../utils/constants";
const apiEndpoints = {
  generateCoupon: {
    dev: `${constants.API_URL}/sellerApi/generateCoupon`,
    prod: `${constants.API_URL}/sellerApi/generateCoupon`,
  },
};

export default class AdminService {
  async generateCoupon(timeLengthInMonth: number, code: string): Promise<void> {
    return new Promise(async (res, rej) => {
      const apiEndpoint =
        buildEnv.BUILD_ENV == "DEV"
          ? apiEndpoints.generateCoupon.dev
          : apiEndpoints.generateCoupon.prod;
      const idToken = await $firebaseAuth.currentUser?.getIdToken();
      if (idToken) {
        const headers = {
          Authorization: `Bearer ${idToken}`,
        };
        console.log(apiEndpoint);
        console.log(headers);
        axios
          .post(
            apiEndpoint,
            { timeLengthInMonth: timeLengthInMonth, code: code },
            {
              headers: headers,
            }
          )
          .then((response: Record<any, any>) => {
            console.log(response.data);

            res();
          })
          .catch((e) => {
            console.log(e);
            rej(e);
          });
      } else {
        rej({ error: "Not auth" });
      }
    });
  }
  listenToCoupons(listenerCallback: Function) {
    const couponsListener = $firestore
      .collection("coupons")
      .where("consumed", "==", false)
      .orderBy("created_at", "desc")
      .onSnapshot((querySnasphot: any) => {
        let coupons: any = [];

        querySnasphot.forEach((doc: any) => {
          coupons.push(doc.data());
        });
        listenerCallback(coupons);
      });
    return couponsListener;
  }
}
