import { Redirect, Route, Switch } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import SignUpPage from "./pages/Start/SignUpPage/SignUpPage";
import ResetPasswordPage from "./pages/Start/ResetPasswordPage/ResetPasswordPage";
import StartPage from "./pages/Start/StartPage";
import Home from "./pages/Home/Home";
import SignInPage from "./pages/Start/SignInPage/SignInPage";
import SignOutPage from "./pages/Start/SignOutPage/SignOutPage";
import FAQ from "./components/FAQ/FAQ";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import routes from "./routes";

/* Theme variables */
import "./theme/variables.css";

const { gtag, install } = require("ga-gtag");

const TRACKING_ID = "G-SQ5K8V3X8H"; // OUR_TRACKING_ID
install(TRACKING_ID);

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path={routes.ROOT}>
          <Redirect to={routes.HOME_PAGE} />
        </Route>
        <Route path={routes.HOME_PAGE}>
          <Home />
        </Route>
        <Route exact path={routes.START_PAGE}>
          <StartPage />
        </Route>
        <Route exact path={routes.SIGN_UP_PAGE}>
          <SignUpPage />
        </Route>
        <Route exact path={routes.SIGN_IN_PAGE}>
          <SignInPage />
        </Route>
        <Route exact path={routes.RESET_PASSWORD_PAGE}>
          <ResetPasswordPage />
        </Route>
        <Route exact path={routes.SIGN_OUT}>
          <SignOutPage />
        </Route>
        <Route exact path={"/stripe_email_confirm"}>
          <div>Stripe verif :   jj.infogerance@gmail.com</div>
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
