import React from "react";
import { IonContent, IonImg, IonPage } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import NavBar from "../../components/NavBar/NavBar";
import SubscribeToPlanComponent from "../../components/Subscribe/SubscribeToPlanComponent";
import HomeHeader from "../../components/Home/HomeHeader/HomeHeader";
import SubscriptionSuccess from "../../components/Success/SubscriptionSuccess";
import Account from "../../components/Account/Account";
import ChooseSubscriptionPlan from "../../components/ChooseSubscripionPlan/ChooseSubscriptionPlan";
import HomeContext from "../../contexts/home.ctx";
import Footer from "../../components/Footer/Footer";
import Video from "../../components/Video/Video";
import AuthContext, {
  SubscriptionPlan,
  AuthStates,
} from "../../contexts/auth.ctx";
import { Redirect, Route, Switch } from "react-router-dom";
import RefrechStripeAccountLink from "../RefrechStripeAccountLink/RefrechStripeAccountLink";
import StripeAccountSuccess from "../StripeAccountSuccess/StripeAccountSuccess";
import SubscriptionCanceled from "../../components/SubscriptionCanceled/SubscriptionCanceled";
import Contact from "../../components/Contact/Contact";
import HomeMainContent from "../../components/Home/HomeMainContent/HomeMainContent";
import Fees from "../../components/Fees/Fees";
import FAQ from "../../components/FAQ/FAQ";
import Download from "../../components/Download/Download";
import AppLoading from "../../components/@Shared/AppLoading/AppLoading";
import routes from "../../routes";
import Admin from "../../components/Admin/Admin";
import "./Home.css";

const Home: React.FC = (props) => {
  const homeCtx = useContext(HomeContext);
  const authCtx = useContext(AuthContext);
  const [showPage, setShowPage] = useState(true);

  if (authCtx.authState == AuthStates.NOT_INIT) {
    return (
      <IonPage>
        <IonContent fullscreen>
          <AppLoading />
        </IonContent>
      </IonPage>
    );
  }
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="home-container">
          <NavBar setShowPage={setShowPage} />

          {showPage ? (
            <>
              <Route exact path={routes.ROOT}>
                <HomeHeader />
                <HomeMainContent />
              </Route>
              <Route exact path={routes.ADMIN}>
                <Admin />
              </Route>

              <Route exact path={routes.HOME_PAGE}>
                <HomeHeader />
                <HomeMainContent />
              </Route>
              <Route exact path={routes.ROOT}>
                <HomeHeader />
                <HomeMainContent />
              </Route>
              <Route exact path={routes.SUBSCRIBE_BASIC}>
                <SubscribeToPlanComponent subscriptionPlan={"BASIC"} />
              </Route>
              <Route exact path={routes.SUBSCRIBE_PREMIUM}>
                <SubscribeToPlanComponent subscriptionPlan={"PREMIUM"} />
              </Route>
              <Route exact path={routes.SUBSCRIBE_PREMIUM_WITH_MATERIAL}>
                <SubscribeToPlanComponent
                  subscriptionPlan={"PREMIUM_WITH_MATERIAL"}
                />
              </Route>
              <Route exact path={routes.SUBSCRIPTION_SUCCESS}>
                <SubscriptionSuccess />
              </Route>
              <Route exact path={routes.ACCOUNT_PAGE}>
                <Account />
              </Route>
              <Route exact path={routes.CHOOSE_SUBSCRIPTION_PLAN}>
                <ChooseSubscriptionPlan />
              </Route>
              <Route exact path={routes.VIDEO}>
                <Video />
              </Route>
              <Route exact path={routes.REFRESH_STRIPE_LINK}>
                <RefrechStripeAccountLink />
              </Route>
              <Route exact path={routes.STRIPE_ACCOUNT_SUCCESS}>
                <StripeAccountSuccess />
              </Route>
              <Route exact path={routes.CONTACT}>
                <Contact />
              </Route>
              <Route exact path={routes.FEES}>
                <Fees />
              </Route>
              <Route exact path={routes.FAQ}>
                <FAQ />
              </Route>
              <Route exact path={routes.DOWNLOAD}>
                <Download />
              </Route>
              <Route exact path={routes.SUBSCRIPTION_CANCELED}>
                <SubscriptionCanceled />
              </Route>
            </>
          ) : null}
          <Footer />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
