import { RestaurantDataContextProvider } from "../restaurant-data.ctx";
// import { ProductsPageContextProvider } from "../products-page.ctx";
// import { NewOrderContextProvider } from "../new-order.ctx";
// import { NewCustomerContextProvider } from "../new-customer.ctx";
import { AuthContextProvider } from "../auth.ctx";
import { SubscriptionOrderContextProvider } from "../subscription-order.ctx";
import { HomeContextProvider } from "../home.ctx";

const RootProvider: React.FC = (props) => {
  return (
    <AuthContextProvider>
      <HomeContextProvider>
        <RestaurantDataContextProvider>
          <SubscriptionOrderContextProvider>
            {props.children}
          </SubscriptionOrderContextProvider>
        </RestaurantDataContextProvider>
      </HomeContextProvider>
    </AuthContextProvider>
  );
};

export default RootProvider;
