import "./Logo.css";
import { IonImg } from "@ionic/react";
import logo from "../../../assets/logo/logo.png";
import logoIcon from "../../../assets/logo/logo-icon.png";

type LogoSizes = "xxs" | "xs" | "sm" | "base" | "md" | "lg" | "xl";

interface ContainerProps {
  size?: LogoSizes;
  icon?: boolean;
}

export const getClassNames = (size?: LogoSizes) => {
  const classes = ["logo"];
  if (size) {
    classes.push("logo--" + size);
  }
  return classes.join(" ");
};

export const getLogoVersion = (icon?: boolean) => {
  return icon ? logoIcon : logo;
};

const Logo: React.FC<ContainerProps> = (props) => {
  return (
    <div className={getClassNames(props.size)} data-testid="logo">
      <IonImg src={getLogoVersion(props.icon)} data-testid="logo__ion-img" />
    </div>
  );
};

export default Logo;
