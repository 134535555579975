import React, { useContext, useState, useRef } from "react";
import { IonIcon, IonImg } from "@ionic/react";
import { add, remove } from "ionicons/icons";
import stripeLogo from "../../assets/logo/stripe_logo.png";
import AuthContext, {
  AuthStates,
  SubscriptionPlan,
} from "../../contexts/auth.ctx";
import HomeContext from "../../contexts/home.ctx";
import CheckoutForm from "../Checkout/CheckoutForm";
import "./Collapsible.css";
import StripePayment from "../../assets/images/logo-stripe.png";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import buildEnv from "../../build.env.json";

interface CollapsibleProps {
  label: string;
}
const Collapsible: React.FC<CollapsibleProps> = (props) => {
  const [open, setOPen] = useState(false);
  const toggle = () => {
    setOPen(!open);
  };
  const contentRef = useRef<any>();
  return (
    <div className="collapsible-container" onClick={toggle}>
      <div className="collapsible-label-container">
        <div className="collapsible-label-icon">
          <IonIcon
            className="call-button__call-button-icon"
            icon={open ? remove : add}
            // color={"light"}
          />
        </div>
        {props.label}
      </div>
      <div
        className="content-parent"
        ref={contentRef}
        style={
          open
            ? { height: contentRef.current.scrollHeight + "px" }
            : { height: "0px" }
        }
      >
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
};

export default Collapsible;
