import { IonButton, IonSpinner, IonImg } from "@ionic/react";
import handPointer from "../../../assets/images/handPointer.png";
import arrowPointer from "../../../assets/images/arrow-pointer-solid.png";
import "./BaseButton.css";
import useWindowDimensions from "../../WindowSize/useWindowDimensions";

// REVIEW test props functions
interface ContainerProps {
  type?: "button" | "submit" | "reset" | undefined;
  outline?: boolean;
  big?: boolean;
  routerLink?: string;
  expand?: boolean;
  onClick?: Function;
  disabled?: boolean;
  isLoading?: boolean;
  tight?: boolean;
  margins?: string;
  color?: string;
  showMouse?: boolean;
}

export const getExpandDefinitionFromProps = (
  expandProps: boolean | undefined
) => {
  return expandProps ? "block" : undefined;
};

export const getFillDefintionFromProps = (
  outlineProps: boolean | undefined
) => {
  return outlineProps ? "outline" : "solid";
};

export const getClassNameFromProps = (
  bigProps: boolean | undefined,
  tightProps: boolean | undefined
) => {
  const classes = ["base-button"];
  if (bigProps) {
    classes.push("base-button--big");
  }
  if (tightProps) {
    classes.push("base-button--tight");
  }

  return classes.join(" ");
};

const BaseButton: React.FC<ContainerProps> = (props) => {
  const { height, width } = useWindowDimensions();
  return (
    <div className="base-button__container">
      <IonButton
        routerLink={props.routerLink}
        mode="ios"
        strong
        expand={getExpandDefinitionFromProps(props.expand)}
        fill={getFillDefintionFromProps(props.outline)}
        className={getClassNameFromProps(props.big, props.tight)}
        onClick={(event) => (props.onClick ? props.onClick(event) : null)}
        data-testid="base-button"
        type={props.type}
        disabled={props.disabled || props.isLoading}
        size={props.tight ? "small" : "default"}
        style={{ margin: props.margins }}
        color={props.color ? props.color : undefined}
      >
        {props.isLoading ? (
          <IonSpinner data-testid="base-button__loading-spinner" />
        ) : (
          <>
            <span style={{ color: props.color ? "white" : undefined }}>
              {props.children}
            </span>
          </>
        )}
      </IonButton>
      {props.showMouse ? (
        <IonImg
          src={width < 700 ? handPointer : arrowPointer}
          className="base-button__overlay"
        />
      ) : null}
    </div>
  );
};

export default BaseButton;
