import { useContext } from "react";
import SignInContext from "../../../contexts/sign-in.ctx";
import AuthContext, { AuthStates} from "../../../contexts/auth.ctx";
import BaseForm from "../../@Base/BaseForm/BaseForm";
import "./SignInForm.css";

interface ContainerProps {}

export const LOGO_ICON_MODE = true;

const SignInForm: React.FC<ContainerProps> = (props) => {
  const signInCtx = useContext(SignInContext);
  const authCtx =  useContext(AuthContext);

  return (
    <section className="signin-form" data-testid="signin-form">
      <BaseForm
        schema={signInCtx.formSchema}
        onSubmit={(data: Record<string, any>) =>
          signInCtx.formSubmitHandler(data.email, data.password)
        }
        submitText="Connexion"
        isBusy={signInCtx.isBusy || authCtx.isLoading}
        title="Mes identifiants :"
      />
    </section>
  );
};

export default SignInForm;
