import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { IonImg, IonIcon } from "@ionic/react";
import {
  person,
  videocam,
  chatbubble,
  checkmarkCircle,
  call,
  mail,
} from "ionicons/icons";
import stripeLogo from "../../assets/logo/stripe_logo.png";
import "./Contact.css";
import HomeContext from "../../contexts/home.ctx";
import SubscriptionOrderContext from "../../contexts/subscription-order.ctx";
import RestaurantDataContext from "../../contexts/restaurant-data.ctx";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import SubscriptionInfoLayout from "../SubscriptionInfoLayout/SubscriptionInfoLayout";
import BaseButton from "../@Base/BaseButton/BaseButton";
import CallButton from "../CallButton/CallButton";
interface ContainerProps {}

const Contact: React.FC = (props) => {
  const homeCtx = useContext(HomeContext);
  const subOrderCtx = useContext(SubscriptionOrderContext);
  const restaurantDataCtx = useContext(RestaurantDataContext);
  const authCtx = useContext(AuthContext);

  return (
    <div className="contact-main-container">
      <div className="contact-simple-text">
        IziUP est une application française. Nous sommes à votre écoute par
        téléphone 7 jours sur 7 de 8h à 20h. Via téléphone ou par mail.
      </div>
      <div className="contact-simple-text">
        Les clients abonnés bénéficient d'une ligne d'urgence disponible de 8h à
        minuit.
      </div>

      <div className="contact-footer-main-container">
        <div className="contact-footer-container">
          <div className="contact-footer-icnon-container">
            <IonIcon icon={person} />
          </div>
          <div className="contact-footer-call-container">
            <div className="contact-footer-text-container">09 55 16 96 19</div>
            <a
              className="contact-footer-call-btn"
              href="tel:0955169619"
              style={{ marginLeft: 12, textDecoration: "none" }}
            >
              <BaseButton
                expand
                showMouse
                type="button"
                outline
                // onClick={() => {
                //   history.push(routes.CONTACT);
                // }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IonIcon
                    style={{
                      height: "24px",
                      width: "24px",
                    }}
                    icon={call}
                    color={"red"}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft: "0.75rem",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bolder",
                        // textShadow: "0px 3px 6px #00000059",
                        textDecoration: "none",
                      }}
                    >
                      Applez-nous
                    </span>
                  </div>
                </div>
              </BaseButton>
            </a>
          </div>
        </div>

        <div className="contact-footer-container">
          <div className="contact-footer-icnon-container">
            <IonIcon icon={videocam} />
          </div>
          <div className="contact-footer-call-container">
            <div className="contact-footer-text-container">
              contact@iziup.fr
            </div>
            <a
              className="contact-footer-mail-btn"
              href="mailto:contact@iziup.fr"
              style={{
                marginLeft: 18,
                textDecoration: "none",
                backgroundColor: "transparent",
              }}
            >
              <BaseButton
                expand
                showMouse
                type="button"
                outline
                // onClick={() => {
                //   history.push(routes.CONTACT);
                // }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IonIcon
                    style={{
                      height: "24px",
                      width: "24px",
                    }}
                    icon={mail}
                    color={"red"}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft: "0.75rem",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bolder",
                        // textShadow: "0px 3px 6px #00000059",
                        textDecoration: "none",
                      }}
                    >
                      Contactez-nous
                    </span>
                  </div>
                </div>
              </BaseButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
