import { useContext } from "react";
import ResetPasswordContext from "../../../contexts/reset-password.ctx";
import BaseForm from "../../@Base/BaseForm/BaseForm";
import Logo from "../../@Shared/Logo/Logo";
import "./ResetPasswordForm.css";

interface ContainerProps {}

export const LOGO_ICON_MODE = true;

const ResetPasswordForm: React.FC<ContainerProps> = (props) => {
  const resetPasswordCtx = useContext(ResetPasswordContext);

  return (
    <section className="reset-password-form" data-testid="reset-password-form">
      <BaseForm
        schema={resetPasswordCtx.formSchema}
        onSubmit={(data: Record<string, any>) =>
          resetPasswordCtx.formSubmitHandler(data.email)
        }
        submitText="Envoyer email de récupération"
        isBusy={resetPasswordCtx.isBusy}
        title="Information de récupération :"
      />
    </section>
  );
};

export default ResetPasswordForm;
